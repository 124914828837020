import { Button, Grid } from "@material-ui/core";
import { UploadItemLeftPanel } from "./UploadItemLeftPanel";
import { Formik, FormikProps } from "formik";
import { ItemInfoInForm, ResultItemUploadInForm } from "../../../types/items.type";
import { UploadItemRightPanel } from "./UploadItemRightPanel";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { collectionCreate, nftCreate } from "../../../store/slices/nftSlice";
import { getCollectionBySymbol } from "../../../api/nftAPI";
import { uploadItemInMarketplace } from "../../../store/slices/libOfThingsSlice";
import { toast } from 'react-hot-toast';
import { useContext, useState } from "react";
import { LibraryOfThingsContractContext } from "../../../providers/LibraryOfThingsContractProvider";
import { CustomToaster } from "../../Notifications/CustomToaster";
import { UploadSummaryItemUpload } from "./UploadSummaryItemUpload";
import { makeStyles } from "@mui/styles";
import { Event } from "ethers"

const style = makeStyles({
    rightContainer: {
        paddingTop: '1em',
    },
    '@media (max-width: 600px)': {
        rightContainer: {
            padding: '1em'
        },
    },

});

export const UploadItemFormCard = ({ showButtons, buttonsShown }: {
    showButtons?: () => void,
    buttonsShown: boolean
}) => {
    const dispatch = useAppDispatch();
    const user = useAppSelector((state) => state.user.currentProfile);
    const { setLoading } = useContext(LibraryOfThingsContractContext);
    const [resultUpload, setResultUpload] = useState<ResultItemUploadInForm>({
        nftID: 0,
        itemID: 0,
        collectionAddress: '',
    })
    const [openSummary, setOpenSummary] = useState(false);
    const classes = style();

    const handleSubmit = async (formValues: ItemInfoInForm) => {
        const toastId = toast.loading(`Attendi qualche secondo, la blockchain sta creando il gemello digitale del tuo oggetto (NFT).`);
        setLoading(true);
        try {
            const address = user?.additional_properties?.commonshoodWallet;
            const username = user?.name.replaceAll(' ', '').trim();
            let collectionAddress = '';
            if (formValues.image == null || formValues.name == null || formValues.category == null || formValues.category.length === 0) {
                toast.error(`Inserisci correttamente le informazioni richieste`);
                return;
            }
            if (address) {
                const collectionsOwned = (await getCollectionBySymbol(`LOT${username}-0`));
                if (Object.keys(collectionsOwned).length === 0 && formValues.image != null) {
                    const collection = await createCollection(formValues.image);
                    if (collection != null)
                        collectionAddress = collection.contractAddress;
                } else {
                    collectionAddress = collectionsOwned.contractAddress;
                }
                const createNftRes = await createNFT(formValues);
                const tokenId = createNftRes.events.filter((ev: Event) => ev.event === "NftAdded")[0].args.tokenId
                console.log(`TokenId ${tokenId}`);
                if (tokenId) {
                    const itemTrx = await createItem(parseInt(tokenId), formValues);
                    console.log(`Item trx: ${itemTrx}`)
                    const e = itemTrx.events.filter((ev: Event) => ev.event == 'ItemAddedInMarketplace')[0];
                    if (e == null)
                        throw new Error('Errore durante l\'inserimento dell\'oggetto nel marketplace');
                    let itemId = e.args._idItem;
                    console.log(`ItemID ${itemId}`)
                    if (itemId && tokenId && collectionAddress) {
                        console.log(`Setting the result of upload`);
                        setResultUpload({
                            nftID: parseInt(tokenId),
                            itemID: parseInt(itemId),
                            collectionAddress: collectionAddress
                        });
                    }
                    setOpenSummary(true);
                    if (showButtons != null)
                        showButtons();
                    console.log(`End of upload`);
                }
            }
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
            toast.dismiss(toastId);
        }
    }

    const createCollection = async (image: File | null) => {
        const username = user?.name.replaceAll(' ', '').trim();
        const collection = await dispatch(collectionCreate({
            name: username + '0',
            description: 'Collection for the Library of Things',
            attributes: [],
            image: image,
            file: new File([""], ''),
            symbol: `LOT${username?.trim()}-0`
        }));
        return collection;
    }

    const createNFT = async (formValues: ItemInfoInForm) => {
        const username = user?.name.replaceAll(' ', '').trim();
        const collection = await getCollectionBySymbol(`LOT${username}-0`);
        const nft = {
            name: formValues.name,
            description: '',
            image: formValues.image,
            getFromCollection: false,
            numbersNft: 1,
            attributes: [],
            collectionAddress: collection.contractAddress,
            isTransferable: true,
            type: 'ITEM'
        }
        const dispatchRes = await dispatch(nftCreate(nft));
        return dispatchRes;
    }

    const createItem = async (tokenId: number, formValues: ItemInfoInForm) => {
        const username = user?.name.replaceAll(' ', '').trim();
        const collection = await getCollectionBySymbol(`LOT${username}-0`);
        const item = {
            name: formValues.name,
            caution: 0,
            price: 100,
            idInCollection: tokenId,
            symbol: `LOT${username}-0`,
            symbolOfTokenAcceptedAsPayment: 'COSO',
            category: formValues.category
        };
        console.log(`Item`);
        console.log(item);
        const trx = await dispatch(
            uploadItemInMarketplace(item, collection.contractAddress)
        );
        return trx;
    }

    return (
        <>
            <CustomToaster />
            <Formik
                initialValues={{
                    image: null,
                    name: '',
                    category: 'Giardinaggio'
                }}
                onSubmit={handleSubmit}
                validate={(values) => { }}
            >
                {(formik: FormikProps<ItemInfoInForm>) => (
                    <form onSubmit={formik.handleSubmit} style={{
                        width: '100%',
                        height: '100%',
                        minHeight: '470px',
                        borderRadius: '15px',
                        border: '1px solid rgba(0, 0, 0, 1)'
                    }}>
                        {
                            openSummary ? (
                                <Grid container xs={12} >
                                    <Grid item xs={12} className={classes.rightContainer}>
                                        <UploadSummaryItemUpload formik={formik} buttonsShown={buttonsShown} />
                                    </Grid>
                                </Grid>
                            ) : (
                                <Grid container xs={12} >
                                    <Grid item xs={12} sm={5}>
                                        <UploadItemLeftPanel formik={formik} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <UploadItemRightPanel formik={formik} />
                                    </Grid>
                                </Grid>
                            )
                        }
                    </form >
                )}
            </Formik >
        </>
    );
}