import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { Item } from "../../../types/libofthings.type";
import { Grid, useMediaQuery } from "@material-ui/core";
import { UploadItemTitle } from "../UploadItem/UploadItemTitle";
import { useHistory } from "react-router-dom";
import { ListOfCards } from "../NewArrivals/ListOfCards";
import { LibraryOfThingsContractContext } from "../../../providers/LibraryOfThingsContractProvider";
import { PaginationContainer } from "../../../components/UI/Pagination/PaginationContainer";
import { TutorialFab } from "../Tutorial/TutorialFab";
import { TutorialContext } from "../../../providers/TutorialProvider";

type QueryParams = {
    address: string,
    email: string
}

export const UserPageWithItems = () => {
    const { address, email } = useParams<QueryParams>();
    const [items, setItems] = useState<Item[]>([]);
    const [numberOfItems, setNumberOfItems] = useState<number>(0); //The total number of items a user owns, needed for pagination
    const { getUserItems, numberOfItemsPerPage } = useContext(LibraryOfThingsContractContext);
    const history = useHistory();
    const isMobile = useMediaQuery('(max-width:600px)');
    const { openTutorialDesktop, openTutorialMobile } = useContext(TutorialContext);

    useEffect(() => {
        (async () => {
            await getItems();
        })();
    }, [address])

    const goBack = () => {
        history.goBack();
    }

    const getItems = async () => {
        const items = await getUserItems(address, 0);
        if (items.items != null) {
            setItems(items.items);
            setNumberOfItems(items.numberOfItems);
        }
    }

    const tutorialFabCallback = () => {
        if (isMobile)
            openTutorialMobile();
        else
            openTutorialDesktop();
    }

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12} onClick={goBack} style={{ cursor: "pointer" }}>
                    <UploadItemTitle text={`Oggetti di ${email}`} />
                </Grid>
                <Grid item xs={12}>
                    <ListOfCards items={items} />
                </Grid>
                <Grid item xs={12}>
                    <PaginationContainer
                        pageCount={Math.ceil(numberOfItems / numberOfItemsPerPage)}
                        onChange={async (selectedPage: number) => {
                            const items = await getUserItems(address, selectedPage);
                            if (items.items != null) {
                                setItems(items.items);
                            }
                        }}
                    />
                </Grid>
            </Grid>
        </>

    );
}