const config = {
    network: {
        // Network elements
        authserver: {
            firstlife_auth: {
                base_url: "https://oauth.dev.firstlife.org/",
                response_type: "code",
                client_id: "2PBsan1f", //dev: 2PBsan1f ----- prod:ciIoXWo2
                redirect_uri: "https://chgarden-beta.commonshood.eu/", //http://localhost:3000/https://beta-dapp.commonshood.eu/
            },
            firstlife_token: {
                base_url: "https://api.commonshood.dev.firstlife.org/", //DEV
                client_id: "2PBsan1f", //dev: 2PBsan1f ----- prod:ciIoXWo2
            },
        },
        firstLifeApi: {
            url: "https://api.commonshood.dev.firstlife.org/",
            tile_server:
                "https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=",
            mapbox_apiKey:
                "pk.eyJ1IjoibWljNzAxIiwiYSI6ImNsZWhrdjk5ajBxZXUzcGxkeGhrc3dhb2oifQ.HSkWc20LUunOVhm-noiAtA",
        },
        walletApi: {
            url: "https://walletapi.di.unito.it/",
        },
        resources: {
            url: "https://storage.dev.firstlife.org/files/", //DEV
        },
        notifications: {
            url: "https://notifications-dapp.commonshood.eu/",
        },
        ipfs: {
            default_url: "ipfs://",
            api_url: "https://resources-beta.commonshood.eu/",
            gateway_url: "https://resources-beta.commonshood.eu/ipfs/",
        },

        metadata: {
            url: "https://metadata-dapp.commonshood.eu/",
        },
    },
    interface: {
        //Interface configuration elements
        snackbar: {
            maxSnack: 3,
        },
    },
    logging: {
        level: "dev", //"prod" for production
    },
    crowdsaleStatus: {
        0: "RUNNING",
        1: "STOPPED",
        2: "LOCKED",
    },
    exchangeStatus: {
        RUNNING: 0,
        COMPLETED: 1,
        CANCELLED: 2,
    },
    blockchain: {
        networkID: 20231012,
        rpcEndpoint: "https://rpcnode-test.ch-ws01.di.unito.it/",
        explorer: "https://explorer-dev.ch-ws01.di.unito.it",
    },

    smartContracts: {
        CALENDAR_ABI: [
            {
                anonymous: false,
                inputs: [
                    {
                        indexed: false,
                        internalType: "uint8",
                        name: "version",
                        type: "uint8",
                    },
                ],
                name: "Initialized",
                type: "event",
            },
            {
                anonymous: false,
                inputs: [
                    {
                        indexed: true,
                        internalType: "address",
                        name: "previousOwner",
                        type: "address",
                    },
                    {
                        indexed: true,
                        internalType: "address",
                        name: "newOwner",
                        type: "address",
                    },
                ],
                name: "OwnershipTransferred",
                type: "event",
            },
            {
                anonymous: false,
                inputs: [
                    {
                        indexed: true,
                        internalType: "bytes32",
                        name: "_preorderID",
                        type: "bytes32",
                    },
                    {
                        indexed: false,
                        internalType: "uint256",
                        name: "_idItem",
                        type: "uint256",
                    },
                    {
                        indexed: false,
                        internalType: "address",
                        name: "_borrower",
                        type: "address",
                    },
                    {
                        indexed: false,
                        internalType: "address",
                        name: "_lender",
                        type: "address",
                    },
                    {
                        indexed: false,
                        internalType: "string",
                        name: "contractVersion",
                        type: "string",
                    },
                    {
                        indexed: false,
                        internalType: "string",
                        name: "_itemName",
                        type: "string",
                    },
                ],
                name: "PreorderAccepted",
                type: "event",
            },
            {
                anonymous: false,
                inputs: [
                    {
                        indexed: true,
                        internalType: "bytes32",
                        name: "_preorderID",
                        type: "bytes32",
                    },
                    {
                        indexed: false,
                        internalType: "uint256",
                        name: "_idItem",
                        type: "uint256",
                    },
                    {
                        indexed: false,
                        internalType: "address",
                        name: "_borrower",
                        type: "address",
                    },
                    {
                        indexed: false,
                        internalType: "address",
                        name: "_lender",
                        type: "address",
                    },
                    {
                        indexed: false,
                        internalType: "string",
                        name: "contractVersion",
                        type: "string",
                    },
                    {
                        indexed: false,
                        internalType: "string",
                        name: "_itemName",
                        type: "string",
                    },
                ],
                name: "PreorderCancelled",
                type: "event",
            },
            {
                anonymous: false,
                inputs: [
                    {
                        indexed: true,
                        internalType: "address",
                        name: "_lender",
                        type: "address",
                    },
                    {
                        indexed: true,
                        internalType: "address",
                        name: "_borrower",
                        type: "address",
                    },
                    {
                        indexed: true,
                        internalType: "bytes32",
                        name: "_preorderID",
                        type: "bytes32",
                    },
                    {
                        indexed: false,
                        internalType: "uint256",
                        name: "_idItem",
                        type: "uint256",
                    },
                    {
                        indexed: false,
                        internalType: "uint256",
                        name: "_startDate",
                        type: "uint256",
                    },
                    {
                        indexed: false,
                        internalType: "uint256",
                        name: "_endDate",
                        type: "uint256",
                    },
                    {
                        indexed: false,
                        internalType: "string",
                        name: "contractVersion",
                        type: "string",
                    },
                    {
                        indexed: false,
                        internalType: "string",
                        name: "_itemName",
                        type: "string",
                    },
                ],
                name: "PreorderCreated",
                type: "event",
            },
            {
                anonymous: false,
                inputs: [
                    {
                        indexed: true,
                        internalType: "bytes32",
                        name: "_preorderID",
                        type: "bytes32",
                    },
                    {
                        indexed: false,
                        internalType: "string",
                        name: "_reason",
                        type: "string",
                    },
                    {
                        indexed: false,
                        internalType: "uint256",
                        name: "_idItem",
                        type: "uint256",
                    },
                    {
                        indexed: false,
                        internalType: "address",
                        name: "_borrower",
                        type: "address",
                    },
                    {
                        indexed: false,
                        internalType: "address",
                        name: "_lender",
                        type: "address",
                    },
                    {
                        indexed: false,
                        internalType: "string",
                        name: "contractVersion",
                        type: "string",
                    },
                    {
                        indexed: false,
                        internalType: "string",
                        name: "_itemName",
                        type: "string",
                    },
                ],
                name: "PreorderDenied",
                type: "event",
            },
            {
                anonymous: false,
                inputs: [
                    {
                        indexed: true,
                        internalType: "bytes32",
                        name: "_preorderID",
                        type: "bytes32",
                    },
                    {
                        indexed: false,
                        internalType: "uint256",
                        name: "_idItem",
                        type: "uint256",
                    },
                    {
                        indexed: false,
                        internalType: "address",
                        name: "_borrower",
                        type: "address",
                    },
                    {
                        indexed: false,
                        internalType: "address",
                        name: "_lender",
                        type: "address",
                    },
                    {
                        indexed: false,
                        internalType: "string",
                        name: "contractVersion",
                        type: "string",
                    },
                    {
                        indexed: false,
                        internalType: "string",
                        name: "_itemName",
                        type: "string",
                    },
                    {
                        indexed: false,
                        internalType: "string",
                        name: "_reason",
                        type: "string",
                    },
                    {
                        indexed: false,
                        internalType: "bool",
                        name: "_itemWasFine",
                        type: "bool",
                    },
                ],
                name: "PreorderEnded",
                type: "event",
            },
            {
                anonymous: false,
                inputs: [
                    {
                        indexed: true,
                        internalType: "bytes32",
                        name: "_preorderID",
                        type: "bytes32",
                    },
                    {
                        indexed: false,
                        internalType: "uint256",
                        name: "_idItem",
                        type: "uint256",
                    },
                    {
                        indexed: false,
                        internalType: "address",
                        name: "_borrower",
                        type: "address",
                    },
                    {
                        indexed: false,
                        internalType: "address",
                        name: "_lender",
                        type: "address",
                    },
                    {
                        indexed: false,
                        internalType: "string",
                        name: "contractVersion",
                        type: "string",
                    },
                    {
                        indexed: false,
                        internalType: "string",
                        name: "_itemName",
                        type: "string",
                    },
                ],
                name: "PreorderStarted",
                type: "event",
            },
            {
                anonymous: false,
                inputs: [
                    {
                        indexed: true,
                        internalType: "address",
                        name: "_user",
                        type: "address",
                    },
                    {
                        indexed: false,
                        internalType: "bool",
                        name: "_isFreezed",
                        type: "bool",
                    },
                ],
                name: "UserFreezedSet",
                type: "event",
            },
            {
                inputs: [],
                name: "owner",
                outputs: [
                    {
                        internalType: "address",
                        name: "",
                        type: "address",
                    },
                ],
                stateMutability: "view",
                type: "function",
                constant: true,
            },
            {
                inputs: [],
                name: "realm",
                outputs: [
                    {
                        internalType: "string",
                        name: "",
                        type: "string",
                    },
                ],
                stateMutability: "view",
                type: "function",
                constant: true,
            },
            {
                inputs: [],
                name: "renounceOwnership",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "address",
                        name: "newOwner",
                        type: "address",
                    },
                ],
                name: "transferOwnership",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [],
                name: "version",
                outputs: [
                    {
                        internalType: "string",
                        name: "",
                        type: "string",
                    },
                ],
                stateMutability: "view",
                type: "function",
                constant: true,
            },
            {
                inputs: [
                    {
                        internalType: "string",
                        name: "_realm",
                        type: "string",
                    },
                    {
                        internalType: "string",
                        name: "_version",
                        type: "string",
                    },
                ],
                name: "initialize",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [
                    {
                        components: [
                            {
                                internalType: "uint256",
                                name: "_idItem",
                                type: "uint256",
                            },
                            {
                                internalType: "uint256",
                                name: "_startDate",
                                type: "uint256",
                            },
                            {
                                internalType: "uint256",
                                name: "_endDate",
                                type: "uint256",
                            },
                            {
                                internalType: "bytes32",
                                name: "_hash",
                                type: "bytes32",
                            },
                            {
                                internalType: "bytes",
                                name: "_signature",
                                type: "bytes",
                            },
                        ],
                        internalType:
                            "struct ItemMarketplaceLib.PreorderCreationParams",
                        name: "_newPreorder",
                        type: "tuple",
                    },
                ],
                name: "createPreorder",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "bytes32",
                        name: "_preorderID",
                        type: "bytes32",
                    },
                ],
                name: "cancelPreorder",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "bytes32",
                        name: "_preorderID",
                        type: "bytes32",
                    },
                ],
                name: "reimbursePreorderTokens",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "bytes32",
                        name: "_preorderID",
                        type: "bytes32",
                    },
                ],
                name: "acceptPreorder",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "bytes32",
                        name: "_preorderID",
                        type: "bytes32",
                    },
                    {
                        internalType: "string",
                        name: "_reason",
                        type: "string",
                    },
                ],
                name: "denyPreorder",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "bytes32",
                        name: "_preorderID",
                        type: "bytes32",
                    },
                ],
                name: "startLendingPeriod",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "bytes32",
                        name: "_preorderID",
                        type: "bytes32",
                    },
                    {
                        internalType: "string",
                        name: "_commment",
                        type: "string",
                    },
                    {
                        internalType: "bool",
                        name: "_itemWasFine",
                        type: "bool",
                    },
                ],
                name: "endLendingPeriod",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "uint8",
                        name: "_newLimit",
                        type: "uint8",
                    },
                ],
                name: "setMaxNumberOfDaysOfPreorder",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "address",
                        name: "_newAddr",
                        type: "address",
                    },
                ],
                name: "updateHashAddress",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [],
                name: "getMarketplaceAddress",
                outputs: [
                    {
                        internalType: "address",
                        name: "",
                        type: "address",
                    },
                ],
                stateMutability: "view",
                type: "function",
                constant: true,
            },
            {
                inputs: [],
                name: "getHashRegistryAddress",
                outputs: [
                    {
                        internalType: "address",
                        name: "",
                        type: "address",
                    },
                ],
                stateMutability: "view",
                type: "function",
                constant: true,
            },
            {
                inputs: [],
                name: "getErc20FactoryAddress",
                outputs: [
                    {
                        internalType: "address",
                        name: "",
                        type: "address",
                    },
                ],
                stateMutability: "view",
                type: "function",
                constant: true,
            },
            {
                inputs: [
                    {
                        internalType: "address",
                        name: "_addr",
                        type: "address",
                    },
                ],
                name: "setHAshRegistryAddress",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "address",
                        name: "_addr",
                        type: "address",
                    },
                ],
                name: "setMarketplaceAddress",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "address",
                        name: "_addr",
                        type: "address",
                    },
                ],
                name: "setERC20TokenFactory",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [],
                name: "freezeUser",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [],
                name: "unfreezeUser",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "address",
                        name: "_user",
                        type: "address",
                    },
                ],
                name: "isUserFreezed",
                outputs: [
                    {
                        internalType: "bool",
                        name: "",
                        type: "bool",
                    },
                ],
                stateMutability: "view",
                type: "function",
                constant: true,
            },
            {
                inputs: [
                    {
                        internalType: "bytes32",
                        name: "_preorderID",
                        type: "bytes32",
                    },
                ],
                name: "preorderInfo",
                outputs: [
                    {
                        components: [
                            {
                                internalType: "address",
                                name: "lender",
                                type: "address",
                            },
                            {
                                internalType: "address",
                                name: "borrower",
                                type: "address",
                            },
                            {
                                internalType: "uint256",
                                name: "timestamp",
                                type: "uint256",
                            },
                            {
                                internalType: "uint256",
                                name: "itemID",
                                type: "uint256",
                            },
                            {
                                internalType: "uint256",
                                name: "startDate",
                                type: "uint256",
                            },
                            {
                                internalType: "uint256",
                                name: "endDate",
                                type: "uint256",
                            },
                            {
                                internalType: "uint256",
                                name: "tokensLocked",
                                type: "uint256",
                            },
                            {
                                internalType: "string",
                                name: "itemName",
                                type: "string",
                            },
                            {
                                internalType: "bytes32",
                                name: "preorderID",
                                type: "bytes32",
                            },
                            {
                                internalType:
                                    "enum ItemMarketplaceLib.PreorderStatus",
                                name: "status",
                                type: "uint8",
                            },
                            {
                                internalType: "uint256",
                                name: "caution",
                                type: "uint256",
                            },
                        ],
                        internalType: "struct ItemMarketplaceLib.Preorder",
                        name: "",
                        type: "tuple",
                    },
                ],
                stateMutability: "view",
                type: "function",
                constant: true,
            },
        ],
        CALENDAR_ADDRESS: "0xF28732F1B9FD3DD3471498529851f6fb1f97b52e",
        ITM_MKP_ABI: [
            {
                anonymous: false,
                inputs: [
                    {
                        indexed: false,
                        internalType: "string",
                        name: "_name",
                        type: "string",
                    },
                ],
                name: "CategoryAdded",
                type: "event",
            },
            {
                anonymous: false,
                inputs: [
                    {
                        indexed: false,
                        internalType: "uint8",
                        name: "version",
                        type: "uint8",
                    },
                ],
                name: "Initialized",
                type: "event",
            },
            {
                anonymous: false,
                inputs: [
                    {
                        indexed: true,
                        internalType: "uint256",
                        name: "_idItem",
                        type: "uint256",
                    },
                    {
                        indexed: false,
                        internalType: "uint256",
                        name: "_idNftInCollection",
                        type: "uint256",
                    },
                    {
                        indexed: false,
                        internalType: "uint256",
                        name: "_price",
                        type: "uint256",
                    },
                    {
                        indexed: false,
                        internalType: "uint256",
                        name: "_caution",
                        type: "uint256",
                    },
                    {
                        indexed: true,
                        internalType: "address",
                        name: "_owner",
                        type: "address",
                    },
                    {
                        indexed: false,
                        internalType: "string",
                        name: "_collectionSymbol",
                        type: "string",
                    },
                    {
                        indexed: false,
                        internalType: "string",
                        name: "_symbolOfTokenAcceptedAsPayment",
                        type: "string",
                    },
                    {
                        indexed: false,
                        internalType: "string",
                        name: "_category",
                        type: "string",
                    },
                    {
                        indexed: false,
                        internalType: "string",
                        name: "_name",
                        type: "string",
                    },
                    {
                        indexed: false,
                        internalType: "string",
                        name: "contractVersion",
                        type: "string",
                    },
                ],
                name: "ItemAddedInMarketplace",
                type: "event",
            },
            {
                anonymous: false,
                inputs: [
                    {
                        indexed: false,
                        internalType: "uint256",
                        name: "_idItem",
                        type: "uint256",
                    },
                ],
                name: "ItemHidden",
                type: "event",
            },
            {
                anonymous: false,
                inputs: [
                    {
                        indexed: false,
                        internalType: "uint256",
                        name: "_idItem",
                        type: "uint256",
                    },
                ],
                name: "ItemUnhidden",
                type: "event",
            },
            {
                anonymous: false,
                inputs: [
                    {
                        indexed: true,
                        internalType: "address",
                        name: "previousOwner",
                        type: "address",
                    },
                    {
                        indexed: true,
                        internalType: "address",
                        name: "newOwner",
                        type: "address",
                    },
                ],
                name: "OwnershipTransferred",
                type: "event",
            },
            {
                inputs: [],
                name: "owner",
                outputs: [
                    {
                        internalType: "address",
                        name: "",
                        type: "address",
                    },
                ],
                stateMutability: "view",
                type: "function",
                constant: true,
            },
            {
                inputs: [],
                name: "realm",
                outputs: [
                    {
                        internalType: "string",
                        name: "",
                        type: "string",
                    },
                ],
                stateMutability: "view",
                type: "function",
                constant: true,
            },
            {
                inputs: [],
                name: "renounceOwnership",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [],
                name: "rewardAmount",
                outputs: [
                    {
                        internalType: "uint256",
                        name: "",
                        type: "uint256",
                    },
                ],
                stateMutability: "view",
                type: "function",
                constant: true,
            },
            {
                inputs: [
                    {
                        internalType: "address",
                        name: "newOwner",
                        type: "address",
                    },
                ],
                name: "transferOwnership",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [],
                name: "version",
                outputs: [
                    {
                        internalType: "string",
                        name: "",
                        type: "string",
                    },
                ],
                stateMutability: "view",
                type: "function",
                constant: true,
            },
            {
                inputs: [
                    {
                        internalType: "string",
                        name: "_realm",
                        type: "string",
                    },
                    {
                        internalType: "string",
                        name: "_version",
                        type: "string",
                    },
                ],
                name: "initialize",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [
                    {
                        components: [
                            {
                                internalType: "uint256",
                                name: "price",
                                type: "uint256",
                            },
                            {
                                internalType: "uint256",
                                name: "caution",
                                type: "uint256",
                            },
                            {
                                internalType: "uint256",
                                name: "idInCollection",
                                type: "uint256",
                            },
                            {
                                internalType: "string",
                                name: "name",
                                type: "string",
                            },
                            {
                                internalType: "string",
                                name: "symbol",
                                type: "string",
                            },
                            {
                                internalType: "string",
                                name: "symbolOfTokenAcceptedAsPayment",
                                type: "string",
                            },
                            {
                                internalType: "string",
                                name: "category",
                                type: "string",
                            },
                        ],
                        internalType: "struct ItemMarketplaceLib.NewItemParams",
                        name: "_itemInfo",
                        type: "tuple",
                    },
                ],
                name: "registerItem",
                outputs: [
                    {
                        internalType: "bool",
                        name: "",
                        type: "bool",
                    },
                ],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "uint256",
                        name: "_idItem",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "_amount",
                        type: "uint256",
                    },
                    {
                        internalType: "address",
                        name: "_whoIsRefunded",
                        type: "address",
                    },
                ],
                name: "reimburseTokens",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "address",
                        name: "_newAddress",
                        type: "address",
                    },
                ],
                name: "setERC20FactoryAddress",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "address",
                        name: "_newAddress",
                        type: "address",
                    },
                ],
                name: "setCalendarAddress",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "address",
                        name: "_newAddress",
                        type: "address",
                    },
                ],
                name: "setNFTFactoryAddress",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [],
                name: "getERC20FactoryAddress",
                outputs: [
                    {
                        internalType: "address",
                        name: "",
                        type: "address",
                    },
                ],
                stateMutability: "view",
                type: "function",
                constant: true,
            },
            {
                inputs: [],
                name: "getCalendarAddress",
                outputs: [
                    {
                        internalType: "address",
                        name: "",
                        type: "address",
                    },
                ],
                stateMutability: "view",
                type: "function",
                constant: true,
            },
            {
                inputs: [],
                name: "getNFTFactoryAddress",
                outputs: [
                    {
                        internalType: "address",
                        name: "",
                        type: "address",
                    },
                ],
                stateMutability: "view",
                type: "function",
                constant: true,
            },
            {
                inputs: [
                    {
                        internalType: "uint256",
                        name: "_idItem",
                        type: "uint256",
                    },
                    {
                        internalType: "address",
                        name: "_to",
                        type: "address",
                    },
                ],
                name: "giveItemToken",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "string",
                        name: "_newCategory",
                        type: "string",
                    },
                ],
                name: "addCategory",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "uint256",
                        name: "_id",
                        type: "uint256",
                    },
                ],
                name: "getItemByID",
                outputs: [
                    {
                        components: [
                            {
                                internalType: "uint256",
                                name: "idInMarketplace",
                                type: "uint256",
                            },
                            {
                                internalType: "uint256",
                                name: "idInCollection",
                                type: "uint256",
                            },
                            {
                                internalType: "uint256",
                                name: "price",
                                type: "uint256",
                            },
                            {
                                internalType: "uint256",
                                name: "caution",
                                type: "uint256",
                            },
                            {
                                internalType: "address",
                                name: "owner",
                                type: "address",
                            },
                            {
                                internalType: "string",
                                name: "name",
                                type: "string",
                            },
                            {
                                internalType: "string",
                                name: "collectionSymbol",
                                type: "string",
                            },
                            {
                                internalType: "string",
                                name: "tokenAcceptedAsPayment",
                                type: "string",
                            },
                            {
                                internalType: "string",
                                name: "category",
                                type: "string",
                            },
                            {
                                internalType: "bool",
                                name: "hidden",
                                type: "bool",
                            },
                        ],
                        internalType: "struct ItemMarketplaceLib.Item",
                        name: "",
                        type: "tuple",
                    },
                ],
                stateMutability: "view",
                type: "function",
                constant: true,
            },
            {
                inputs: [],
                name: "getNumberOfitemsInMarketplace",
                outputs: [
                    {
                        internalType: "uint256",
                        name: "",
                        type: "uint256",
                    },
                ],
                stateMutability: "view",
                type: "function",
                constant: true,
            },
            {
                inputs: [
                    {
                        internalType: "string",
                        name: "_symbol",
                        type: "string",
                    },
                ],
                name: "getCollectionAddressFromSymbol",
                outputs: [
                    {
                        internalType: "address",
                        name: "",
                        type: "address",
                    },
                ],
                stateMutability: "view",
                type: "function",
                constant: true,
            },
            {
                inputs: [
                    {
                        internalType: "address",
                        name: "erc20TokenAddress",
                        type: "address",
                    },
                ],
                name: "claimReward",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "address",
                        name: "_user",
                        type: "address",
                    },
                ],
                name: "setReward",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "uint256",
                        name: "_amount",
                        type: "uint256",
                    },
                ],
                name: "setRewardAmount",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "address",
                        name: "_user",
                        type: "address",
                    },
                ],
                name: "rewardClaimed",
                outputs: [
                    {
                        internalType: "bool",
                        name: "",
                        type: "bool",
                    },
                ],
                stateMutability: "view",
                type: "function",
                constant: true,
            },
            {
                inputs: [
                    {
                        internalType: "uint256",
                        name: "_id",
                        type: "uint256",
                    },
                ],
                name: "removeItemFromMarketplace",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "uint256",
                        name: "_id",
                        type: "uint256",
                    },
                ],
                name: "revealItem",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "address",
                        name: "_operator",
                        type: "address",
                    },
                    {
                        internalType: "address",
                        name: "_from",
                        type: "address",
                    },
                    {
                        internalType: "uint256",
                        name: "_tokenId",
                        type: "uint256",
                    },
                    {
                        internalType: "bytes",
                        name: "_data",
                        type: "bytes",
                    },
                ],
                name: "onERC721Received",
                outputs: [
                    {
                        internalType: "bytes4",
                        name: "",
                        type: "bytes4",
                    },
                ],
                stateMutability: "nonpayable",
                type: "function",
            },
        ],
        ITM_MKP_ADDRESS: "0xeC251E717D5ac56E812334C44940Bbd3B7e6BBCa",

        TKN_FCTRY_ABI: [
            {
                anonymous: false,
                inputs: [
                    {
                        indexed: true,
                        internalType: "address",
                        name: "_from",
                        type: "address",
                    },
                    {
                        indexed: true,
                        internalType: "address",
                        name: "_who",
                        type: "address",
                    },
                    {
                        indexed: false,
                        internalType: "string",
                        name: "contractVersion",
                        type: "string",
                    },
                ],
                name: "AdminAdded",
                type: "event",
            },
            {
                anonymous: false,
                inputs: [
                    {
                        indexed: false,
                        internalType: "uint8",
                        name: "version",
                        type: "uint8",
                    },
                ],
                name: "Initialized",
                type: "event",
            },
            {
                anonymous: false,
                inputs: [
                    {
                        indexed: true,
                        internalType: "address",
                        name: "_from",
                        type: "address",
                    },
                    {
                        indexed: false,
                        internalType: "address",
                        name: "_contractAddress",
                        type: "address",
                    },
                    {
                        indexed: false,
                        internalType: "string",
                        name: "_name",
                        type: "string",
                    },
                    {
                        indexed: false,
                        internalType: "string",
                        name: "_symbol",
                        type: "string",
                    },
                    {
                        indexed: false,
                        internalType: "uint8",
                        name: "_decimals",
                        type: "uint8",
                    },
                    {
                        indexed: false,
                        internalType: "string",
                        name: "_logoURL",
                        type: "string",
                    },
                    {
                        indexed: false,
                        internalType: "uint256",
                        name: "_hardCap",
                        type: "uint256",
                    },
                    {
                        indexed: false,
                        internalType: "string",
                        name: "contractVersion",
                        type: "string",
                    },
                ],
                name: "TokenAdded",
                type: "event",
            },
            {
                anonymous: false,
                inputs: [
                    {
                        indexed: true,
                        internalType: "address",
                        name: "_from",
                        type: "address",
                    },
                    {
                        indexed: false,
                        internalType: "string",
                        name: "_op",
                        type: "string",
                    },
                    {
                        indexed: false,
                        internalType: "string",
                        name: "_msg",
                        type: "string",
                    },
                    {
                        indexed: false,
                        internalType: "string",
                        name: "contractVersion",
                        type: "string",
                    },
                ],
                name: "factoryDebug",
                type: "event",
            },
            {
                inputs: [],
                name: "name",
                outputs: [
                    {
                        internalType: "string",
                        name: "",
                        type: "string",
                    },
                ],
                stateMutability: "view",
                type: "function",
                constant: true,
            },
            {
                inputs: [],
                name: "realm",
                outputs: [
                    {
                        internalType: "string",
                        name: "",
                        type: "string",
                    },
                ],
                stateMutability: "view",
                type: "function",
                constant: true,
            },
            {
                inputs: [],
                name: "version",
                outputs: [
                    {
                        internalType: "string",
                        name: "",
                        type: "string",
                    },
                ],
                stateMutability: "view",
                type: "function",
                constant: true,
            },
            {
                inputs: [
                    {
                        internalType: "string",
                        name: "_name",
                        type: "string",
                    },
                    {
                        internalType: "string",
                        name: "_realm",
                        type: "string",
                    },
                    {
                        internalType: "string",
                        name: "_version",
                        type: "string",
                    },
                ],
                name: "initialize",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "address",
                        name: "_address",
                        type: "address",
                    },
                ],
                name: "makeAdmin",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [],
                name: "getAllTokenAddresses",
                outputs: [
                    {
                        internalType: "address[]",
                        name: "",
                        type: "address[]",
                    },
                ],
                stateMutability: "view",
                type: "function",
                constant: true,
            },
            {
                inputs: [
                    {
                        internalType: "address",
                        name: "tokenAddress",
                        type: "address",
                    },
                ],
                name: "getTokenByAddress",
                outputs: [
                    {
                        internalType: "address",
                        name: "",
                        type: "address",
                    },
                    {
                        internalType: "string",
                        name: "",
                        type: "string",
                    },
                    {
                        internalType: "string",
                        name: "",
                        type: "string",
                    },
                    {
                        internalType: "uint8",
                        name: "",
                        type: "uint8",
                    },
                    {
                        internalType: "string",
                        name: "",
                        type: "string",
                    },
                    {
                        internalType: "address",
                        name: "",
                        type: "address",
                    },
                    {
                        internalType: "bool",
                        name: "",
                        type: "bool",
                    },
                ],
                stateMutability: "view",
                type: "function",
                constant: true,
            },
            {
                inputs: [
                    {
                        internalType: "string",
                        name: "symbol",
                        type: "string",
                    },
                ],
                name: "getToken",
                outputs: [
                    {
                        internalType: "address",
                        name: "",
                        type: "address",
                    },
                    {
                        internalType: "string",
                        name: "",
                        type: "string",
                    },
                    {
                        internalType: "string",
                        name: "",
                        type: "string",
                    },
                    {
                        internalType: "uint8",
                        name: "",
                        type: "uint8",
                    },
                    {
                        internalType: "string",
                        name: "",
                        type: "string",
                    },
                    {
                        internalType: "address",
                        name: "",
                        type: "address",
                    },
                    {
                        internalType: "bool",
                        name: "",
                        type: "bool",
                    },
                ],
                stateMutability: "view",
                type: "function",
                constant: true,
            },
            {
                inputs: [
                    {
                        internalType: "address",
                        name: "_possessor",
                        type: "address",
                    },
                    {
                        internalType: "address",
                        name: "_tokenAddress",
                        type: "address",
                    },
                ],
                name: "addToPossessed",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "address",
                        name: "_possessor",
                        type: "address",
                    },
                ],
                name: "getPossessedTokens",
                outputs: [
                    {
                        internalType: "address[]",
                        name: "",
                        type: "address[]",
                    },
                ],
                stateMutability: "view",
                type: "function",
                constant: true,
            },
            {
                inputs: [
                    {
                        internalType: "string",
                        name: "_name",
                        type: "string",
                    },
                    {
                        internalType: "string",
                        name: "_symbol",
                        type: "string",
                    },
                    {
                        internalType: "uint8",
                        name: "_decimals",
                        type: "uint8",
                    },
                    {
                        internalType: "string",
                        name: "_logoURL",
                        type: "string",
                    },
                    {
                        internalType: "string",
                        name: "_logoHash",
                        type: "string",
                    },
                    {
                        internalType: "uint256",
                        name: "_hardCap",
                        type: "uint256",
                    },
                    {
                        internalType: "string",
                        name: "_contractHash",
                        type: "string",
                    },
                ],
                name: "createToken",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "string",
                        name: "_version",
                        type: "string",
                    },
                ],
                name: "upgradeVersion",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "address",
                        name: "_address",
                        type: "address",
                    },
                ],
                name: "checkIfIsAdmin",
                outputs: [
                    {
                        internalType: "bool",
                        name: "",
                        type: "bool",
                    },
                ],
                stateMutability: "view",
                type: "function",
                constant: true,
            },
        ],
        TKN_FCTRY_ADDR: "0x35EA348d78fe92f4Ce2e2723B6623fE593D2769E",

        CRWDSL_FCTRY_ABI: [
            {
                anonymous: false,
                inputs: [
                    {
                        indexed: true,
                        internalType: "address",
                        name: "_from",
                        type: "address",
                    },
                    {
                        indexed: true,
                        internalType: "address",
                        name: "_who",
                        type: "address",
                    },
                ],
                name: "AdminAdded",
                type: "event",
            },
            {
                anonymous: false,
                inputs: [
                    {
                        indexed: true,
                        internalType: "address",
                        name: "_from",
                        type: "address",
                    },
                    {
                        indexed: false,
                        internalType: "address",
                        name: "_contractAddress",
                        type: "address",
                    },
                    {
                        indexed: false,
                        internalType: "uint256",
                        name: "_start",
                        type: "uint256",
                    },
                    {
                        indexed: false,
                        internalType: "uint256",
                        name: "_end",
                        type: "uint256",
                    },
                    {
                        indexed: false,
                        internalType: "uint256",
                        name: "_acceptRatio",
                        type: "uint256",
                    },
                    {
                        indexed: false,
                        internalType: "uint256",
                        name: "_giveRatio",
                        type: "uint256",
                    },
                    {
                        indexed: false,
                        internalType: "uint256",
                        name: "_maxCap",
                        type: "uint256",
                    },
                    {
                        indexed: false,
                        internalType: "string",
                        name: "_title",
                        type: "string",
                    },
                    {
                        indexed: false,
                        internalType: "string",
                        name: "contractVersion",
                        type: "string",
                    },
                ],
                name: "CrowdsaleAdded",
                type: "event",
            },
            {
                anonymous: false,
                inputs: [
                    {
                        indexed: false,
                        internalType: "uint8",
                        name: "version",
                        type: "uint8",
                    },
                ],
                name: "Initialized",
                type: "event",
            },
            {
                inputs: [],
                name: "name",
                outputs: [
                    {
                        internalType: "string",
                        name: "",
                        type: "string",
                    },
                ],
                stateMutability: "view",
                type: "function",
                constant: true,
            },
            {
                inputs: [],
                name: "realm",
                outputs: [
                    {
                        internalType: "string",
                        name: "",
                        type: "string",
                    },
                ],
                stateMutability: "view",
                type: "function",
                constant: true,
            },
            {
                inputs: [],
                name: "version",
                outputs: [
                    {
                        internalType: "string",
                        name: "",
                        type: "string",
                    },
                ],
                stateMutability: "view",
                type: "function",
                constant: true,
            },
            {
                inputs: [
                    {
                        internalType: "string",
                        name: "_name",
                        type: "string",
                    },
                    {
                        internalType: "string",
                        name: "_realm",
                        type: "string",
                    },
                    {
                        internalType: "string",
                        name: "_version",
                        type: "string",
                    },
                ],
                name: "initialize",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "address",
                        name: "_address",
                        type: "address",
                    },
                ],
                name: "makeAdmin",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [],
                name: "getAllCrowdsalesAddresses",
                outputs: [
                    {
                        internalType: "address[]",
                        name: "",
                        type: "address[]",
                    },
                ],
                stateMutability: "view",
                type: "function",
                constant: true,
            },
            {
                inputs: [],
                name: "numberOfCrowdsales",
                outputs: [
                    {
                        internalType: "uint256",
                        name: "",
                        type: "uint256",
                    },
                ],
                stateMutability: "view",
                type: "function",
                constant: true,
            },
            {
                inputs: [
                    {
                        internalType: "address",
                        name: "_owner",
                        type: "address",
                    },
                ],
                name: "getCrowdsalesByOwner",
                outputs: [
                    {
                        internalType: "address[]",
                        name: "",
                        type: "address[]",
                    },
                ],
                stateMutability: "view",
                type: "function",
                constant: true,
            },
            {
                inputs: [
                    {
                        internalType: "address",
                        name: "_tokenToGive",
                        type: "address",
                    },
                    {
                        internalType: "address",
                        name: "_tokenToAccept",
                        type: "address",
                    },
                    {
                        internalType: "uint256",
                        name: "_start",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "_end",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "_acceptRatio",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "_giveRatio",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "_maxCap",
                        type: "uint256",
                    },
                    {
                        internalType: "string[]",
                        name: "metadata",
                        type: "string[]",
                    },
                ],
                name: "createCrowdsale",
                outputs: [
                    {
                        internalType: "address",
                        name: "",
                        type: "address",
                    },
                ],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "address",
                        name: "_toCheck",
                        type: "address",
                    },
                ],
                name: "isAddressCrowdsale",
                outputs: [
                    {
                        internalType: "bool",
                        name: "",
                        type: "bool",
                    },
                ],
                stateMutability: "view",
                type: "function",
                constant: true,
            },
            {
                inputs: [
                    {
                        internalType: "address",
                        name: "_addr",
                        type: "address",
                    },
                ],
                name: "checkIfIsAdmin",
                outputs: [
                    {
                        internalType: "bool",
                        name: "",
                        type: "bool",
                    },
                ],
                stateMutability: "view",
                type: "function",
                constant: true,
            },
            {
                inputs: [
                    {
                        internalType: "string",
                        name: "_version",
                        type: "string",
                    },
                ],
                name: "upgradeVersion",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
        ],
        CRWDSL_FCTRY_ADDR: "0x1D905899e79ef6ceC6eF9EB08F68b2AE30942Ac6",

        DAO_FCTRY_ABI: [
            {
                inputs: [
                    {
                        internalType: "string",
                        name: "_name",
                        type: "string",
                    },
                    {
                        internalType: "string",
                        name: "_realm",
                        type: "string",
                    },
                ],
                stateMutability: "nonpayable",
                type: "constructor",
            },
            {
                anonymous: false,
                inputs: [
                    {
                        indexed: true,
                        internalType: "address",
                        name: "_from",
                        type: "address",
                    },
                    {
                        indexed: true,
                        internalType: "address",
                        name: "_who",
                        type: "address",
                    },
                ],
                name: "AdminAdded",
                type: "event",
            },
            {
                anonymous: false,
                inputs: [
                    {
                        indexed: true,
                        internalType: "address",
                        name: "_from",
                        type: "address",
                    },
                    {
                        indexed: false,
                        internalType: "address",
                        name: "_contractAddress",
                        type: "address",
                    },
                    {
                        indexed: false,
                        internalType: "string",
                        name: "_name",
                        type: "string",
                    },
                    {
                        indexed: false,
                        internalType: "string",
                        name: "_firstlifePlaceID",
                        type: "string",
                    },
                ],
                name: "DAOAdded",
                type: "event",
            },
            {
                inputs: [
                    {
                        internalType: "string",
                        name: "",
                        type: "string",
                    },
                ],
                name: "daos",
                outputs: [
                    {
                        internalType: "contract CcDAO",
                        name: "",
                        type: "address",
                    },
                ],
                stateMutability: "view",
                type: "function",
                constant: true,
            },
            {
                inputs: [],
                name: "name",
                outputs: [
                    {
                        internalType: "string",
                        name: "",
                        type: "string",
                    },
                ],
                stateMutability: "view",
                type: "function",
                constant: true,
            },
            {
                inputs: [],
                name: "realm",
                outputs: [
                    {
                        internalType: "string",
                        name: "",
                        type: "string",
                    },
                ],
                stateMutability: "view",
                type: "function",
                constant: true,
            },
            {
                inputs: [
                    {
                        internalType: "address",
                        name: "_address",
                        type: "address",
                    },
                ],
                name: "makeAdmin",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "string",
                        name: "_name",
                        type: "string",
                    },
                    {
                        internalType: "string",
                        name: "_firstlifePlaceID",
                        type: "string",
                    },
                    {
                        internalType: "string",
                        name: "_description_cid",
                        type: "string",
                    },
                    {
                        internalType: "address",
                        name: "_tokenFactory",
                        type: "address",
                    },
                    {
                        internalType: "address",
                        name: "_crowdsaleFactory",
                        type: "address",
                    },
                    {
                        internalType: "address",
                        name: "_exchangeFactory",
                        type: "address",
                    },
                ],
                name: "createDAO",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "string",
                        name: "_firstlifePlaceID",
                        type: "string",
                    },
                    {
                        internalType: "address",
                        name: "_user",
                        type: "address",
                    },
                ],
                name: "addJoinedUser",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "string",
                        name: "_firstlifePlaceID",
                        type: "string",
                    },
                ],
                name: "getDAO",
                outputs: [
                    {
                        internalType: "address",
                        name: "",
                        type: "address",
                    },
                ],
                stateMutability: "view",
                type: "function",
                constant: true,
            },
            {
                inputs: [
                    {
                        internalType: "address",
                        name: "_user",
                        type: "address",
                    },
                ],
                name: "getDaoAddressJoinedByUser",
                outputs: [
                    {
                        internalType: "contract CcDAO[]",
                        name: "",
                        type: "address[]",
                    },
                ],
                stateMutability: "view",
                type: "function",
                constant: true,
            },
            {
                inputs: [
                    {
                        internalType: "address",
                        name: "_toCheck",
                        type: "address",
                    },
                ],
                name: "isAddressDAO",
                outputs: [
                    {
                        internalType: "bool",
                        name: "",
                        type: "bool",
                    },
                ],
                stateMutability: "view",
                type: "function",
                constant: true,
            },
        ],
        DAO_FCTRY_ADDR: "0xa1EF0303878DD1B0d98fAA39aAc9a091FCEc2378",

        EXCHNG_FCTRY_ABI: [
            {
                anonymous: false,
                inputs: [
                    {
                        indexed: true,
                        internalType: "address",
                        name: "_from",
                        type: "address",
                    },
                    {
                        indexed: true,
                        internalType: "address",
                        name: "_who",
                        type: "address",
                    },
                    {
                        indexed: false,
                        internalType: "string",
                        name: "contractVersion",
                        type: "string",
                    },
                ],
                name: "AdminAdded",
                type: "event",
            },
            {
                anonymous: false,
                inputs: [
                    {
                        indexed: true,
                        internalType: "address",
                        name: "owner",
                        type: "address",
                    },
                    {
                        indexed: true,
                        internalType: "address",
                        name: "exchangeAddr",
                        type: "address",
                    },
                    {
                        indexed: false,
                        internalType: "string",
                        name: "contractVersion",
                        type: "string",
                    },
                ],
                name: "ExchangeAdded",
                type: "event",
            },
            {
                anonymous: false,
                inputs: [
                    {
                        indexed: false,
                        internalType: "uint8",
                        name: "version",
                        type: "uint8",
                    },
                ],
                name: "Initialized",
                type: "event",
            },
            {
                inputs: [],
                name: "name",
                outputs: [
                    {
                        internalType: "string",
                        name: "",
                        type: "string",
                    },
                ],
                stateMutability: "view",
                type: "function",
                constant: true,
            },
            {
                inputs: [],
                name: "realm",
                outputs: [
                    {
                        internalType: "string",
                        name: "",
                        type: "string",
                    },
                ],
                stateMutability: "view",
                type: "function",
                constant: true,
            },
            {
                inputs: [],
                name: "version",
                outputs: [
                    {
                        internalType: "string",
                        name: "",
                        type: "string",
                    },
                ],
                stateMutability: "view",
                type: "function",
                constant: true,
            },
            {
                inputs: [
                    {
                        internalType: "string",
                        name: "_name",
                        type: "string",
                    },
                    {
                        internalType: "string",
                        name: "_realm",
                        type: "string",
                    },
                    {
                        internalType: "string",
                        name: "_version",
                        type: "string",
                    },
                ],
                name: "initialize",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "address[]",
                        name: "_coinsOffered",
                        type: "address[]",
                    },
                    {
                        internalType: "address[]",
                        name: "_coinsRequired",
                        type: "address[]",
                    },
                    {
                        internalType: "uint256[]",
                        name: "_amountsOffered",
                        type: "uint256[]",
                    },
                    {
                        internalType: "uint256[]",
                        name: "_amountsRequired",
                        type: "uint256[]",
                    },
                    {
                        internalType: "uint256",
                        name: "_repeats",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "_expiration",
                        type: "uint256",
                    },
                ],
                name: "createExchange",
                outputs: [
                    {
                        internalType: "address",
                        name: "",
                        type: "address",
                    },
                ],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "address",
                        name: "_address",
                        type: "address",
                    },
                ],
                name: "makeAdmin",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "address",
                        name: "_addr",
                        type: "address",
                    },
                ],
                name: "getExchangeByAddress",
                outputs: [
                    {
                        components: [
                            {
                                internalType: "address",
                                name: "_address",
                                type: "address",
                            },
                            {
                                internalType: "address",
                                name: "_owner",
                                type: "address",
                            },
                        ],
                        internalType: "struct ExchangeFactory.Exchange",
                        name: "",
                        type: "tuple",
                    },
                ],
                stateMutability: "view",
                type: "function",
                constant: true,
            },
            {
                inputs: [
                    {
                        internalType: "string",
                        name: "_version",
                        type: "string",
                    },
                    {
                        internalType: "address[]",
                        name: "exchanges",
                        type: "address[]",
                    },
                ],
                name: "upgradeVersion",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "address",
                        name: "_addr",
                        type: "address",
                    },
                ],
                name: "isAddressExchange",
                outputs: [
                    {
                        internalType: "bool",
                        name: "",
                        type: "bool",
                    },
                ],
                stateMutability: "view",
                type: "function",
                constant: true,
            },
        ],
        EXCHNG_FCTRY_ADDR: "0xEd2392eeA81a2c9f181142A4548F7889378D45d0",

        NFT_FCTRY_ABI: [
            {
                anonymous: false,
                inputs: [
                    {
                        indexed: true,
                        internalType: "address",
                        name: "accountAddress",
                        type: "address",
                    },
                    {
                        indexed: true,
                        internalType: "address",
                        name: "contractAddress",
                        type: "address",
                    },
                    {
                        indexed: false,
                        internalType: "string",
                        name: "name",
                        type: "string",
                    },
                    {
                        indexed: false,
                        internalType: "string",
                        name: "symbol",
                        type: "string",
                    },
                    {
                        indexed: false,
                        internalType: "string",
                        name: "nftType",
                        type: "string",
                    },
                    {
                        indexed: false,
                        internalType: "string",
                        name: "collectionURI",
                        type: "string",
                    },
                    {
                        indexed: false,
                        internalType: "address",
                        name: "owner",
                        type: "address",
                    },
                    {
                        indexed: false,
                        internalType: "string",
                        name: "contractVersion",
                        type: "string",
                    },
                ],
                name: "CollectionAdded",
                type: "event",
            },
            {
                anonymous: false,
                inputs: [
                    {
                        indexed: true,
                        internalType: "address",
                        name: "contractAddress",
                        type: "address",
                    },
                    {
                        indexed: false,
                        internalType: "string",
                        name: "name",
                        type: "string",
                    },
                    {
                        indexed: false,
                        internalType: "string",
                        name: "symbol",
                        type: "string",
                    },
                    {
                        indexed: false,
                        internalType: "string",
                        name: "nftType",
                        type: "string",
                    },
                    {
                        indexed: false,
                        internalType: "string",
                        name: "collectionURI",
                        type: "string",
                    },
                    {
                        indexed: true,
                        internalType: "address",
                        name: "owner",
                        type: "address",
                    },
                    {
                        indexed: false,
                        internalType: "string",
                        name: "contractVersion",
                        type: "string",
                    },
                ],
                name: "CollectionCreated",
                type: "event",
            },
            {
                anonymous: false,
                inputs: [
                    {
                        indexed: true,
                        internalType: "address",
                        name: "accountAddress",
                        type: "address",
                    },
                    {
                        indexed: true,
                        internalType: "address",
                        name: "contractAddress",
                        type: "address",
                    },
                    {
                        indexed: false,
                        internalType: "string",
                        name: "name",
                        type: "string",
                    },
                    {
                        indexed: false,
                        internalType: "string",
                        name: "symbol",
                        type: "string",
                    },
                    {
                        indexed: false,
                        internalType: "string",
                        name: "nftType",
                        type: "string",
                    },
                    {
                        indexed: false,
                        internalType: "string",
                        name: "collectionURI",
                        type: "string",
                    },
                    {
                        indexed: false,
                        internalType: "address",
                        name: "owner",
                        type: "address",
                    },
                    {
                        indexed: false,
                        internalType: "string",
                        name: "contractVersion",
                        type: "string",
                    },
                ],
                name: "CollectionRemoved",
                type: "event",
            },
            {
                anonymous: false,
                inputs: [
                    {
                        indexed: false,
                        internalType: "uint8",
                        name: "version",
                        type: "uint8",
                    },
                ],
                name: "Initialized",
                type: "event",
            },
            {
                anonymous: false,
                inputs: [
                    {
                        indexed: true,
                        internalType: "address",
                        name: "contractAddress",
                        type: "address",
                    },
                    {
                        indexed: false,
                        internalType: "address",
                        name: "from",
                        type: "address",
                    },
                    {
                        indexed: true,
                        internalType: "address",
                        name: "to",
                        type: "address",
                    },
                    {
                        indexed: false,
                        internalType: "string",
                        name: "uri",
                        type: "string",
                    },
                    {
                        indexed: true,
                        internalType: "uint256",
                        name: "tokenId",
                        type: "uint256",
                    },
                    {
                        indexed: false,
                        internalType: "bool",
                        name: "isTransferable",
                        type: "bool",
                    },
                    {
                        indexed: false,
                        internalType: "string",
                        name: "nftType",
                        type: "string",
                    },
                    {
                        indexed: false,
                        internalType: "string",
                        name: "contractVersion",
                        type: "string",
                    },
                ],
                name: "NftAdded",
                type: "event",
            },
            {
                anonymous: false,
                inputs: [
                    {
                        indexed: true,
                        internalType: "address",
                        name: "contractAddress",
                        type: "address",
                    },
                    {
                        indexed: true,
                        internalType: "address",
                        name: "from",
                        type: "address",
                    },
                    {
                        indexed: false,
                        internalType: "address",
                        name: "to",
                        type: "address",
                    },
                    {
                        indexed: false,
                        internalType: "string",
                        name: "uri",
                        type: "string",
                    },
                    {
                        indexed: true,
                        internalType: "uint256",
                        name: "tokenId",
                        type: "uint256",
                    },
                    {
                        indexed: false,
                        internalType: "bool",
                        name: "isTransferable",
                        type: "bool",
                    },
                    {
                        indexed: false,
                        internalType: "string",
                        name: "contractVersion",
                        type: "string",
                    },
                ],
                name: "NftRemoved",
                type: "event",
            },
            {
                anonymous: false,
                inputs: [
                    {
                        indexed: true,
                        internalType: "address",
                        name: "previousOwner",
                        type: "address",
                    },
                    {
                        indexed: true,
                        internalType: "address",
                        name: "newOwner",
                        type: "address",
                    },
                ],
                name: "OwnershipTransferred",
                type: "event",
            },
            {
                inputs: [],
                name: "hashRegistryAddress",
                outputs: [
                    {
                        internalType: "address",
                        name: "",
                        type: "address",
                    },
                ],
                stateMutability: "view",
                type: "function",
                constant: true,
            },
            {
                inputs: [],
                name: "name",
                outputs: [
                    {
                        internalType: "string",
                        name: "",
                        type: "string",
                    },
                ],
                stateMutability: "view",
                type: "function",
                constant: true,
            },
            {
                inputs: [],
                name: "owner",
                outputs: [
                    {
                        internalType: "address",
                        name: "",
                        type: "address",
                    },
                ],
                stateMutability: "view",
                type: "function",
                constant: true,
            },
            {
                inputs: [],
                name: "realm",
                outputs: [
                    {
                        internalType: "string",
                        name: "",
                        type: "string",
                    },
                ],
                stateMutability: "view",
                type: "function",
                constant: true,
            },
            {
                inputs: [],
                name: "renounceOwnership",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "address",
                        name: "newOwner",
                        type: "address",
                    },
                ],
                name: "transferOwnership",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [],
                name: "version",
                outputs: [
                    {
                        internalType: "string",
                        name: "",
                        type: "string",
                    },
                ],
                stateMutability: "view",
                type: "function",
                constant: true,
            },
            {
                inputs: [
                    {
                        internalType: "string",
                        name: "_name",
                        type: "string",
                    },
                    {
                        internalType: "string",
                        name: "_realm",
                        type: "string",
                    },
                    {
                        internalType: "string",
                        name: "_version",
                        type: "string",
                    },
                ],
                name: "initialize",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "string",
                        name: "_name",
                        type: "string",
                    },
                    {
                        internalType: "string",
                        name: "_symbol",
                        type: "string",
                    },
                    {
                        internalType: "string",
                        name: "_collectionURI",
                        type: "string",
                    },
                    {
                        internalType: "string",
                        name: "_type",
                        type: "string",
                    },
                ],
                name: "createNftCollection",
                outputs: [
                    {
                        internalType: "address",
                        name: "",
                        type: "address",
                    },
                ],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "address",
                        name: "_collectionAddress",
                        type: "address",
                    },
                    {
                        internalType: "string",
                        name: "_tokenURI",
                        type: "string",
                    },
                    {
                        internalType: "uint256",
                        name: "_nftNumber",
                        type: "uint256",
                    },
                    {
                        internalType: "bool",
                        name: "_isTransferable",
                        type: "bool",
                    },
                    {
                        internalType: "string",
                        name: "_type",
                        type: "string",
                    },
                ],
                name: "createNftForCollection",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "address",
                        name: "_collectionAddress",
                        type: "address",
                    },
                    {
                        internalType: "address",
                        name: "_to",
                        type: "address",
                    },
                    {
                        internalType: "string",
                        name: "_tokenURI",
                        type: "string",
                    },
                    {
                        internalType: "bool",
                        name: "_isTransferable",
                        type: "bool",
                    },
                    {
                        internalType: "string",
                        name: "_type",
                        type: "string",
                    },
                    {
                        internalType: "bytes32",
                        name: "_hashToCheck",
                        type: "bytes32",
                    },
                    {
                        internalType: "bytes",
                        name: "_signature",
                        type: "bytes",
                    },
                ],
                name: "redeemNft",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "address",
                        name: "_from",
                        type: "address",
                    },
                    {
                        internalType: "address",
                        name: "_to",
                        type: "address",
                    },
                    {
                        internalType: "address",
                        name: "_collectionAddress",
                        type: "address",
                    },
                    {
                        internalType: "uint256",
                        name: "_tokenId",
                        type: "uint256",
                    },
                    {
                        internalType: "string",
                        name: "_type",
                        type: "string",
                    },
                ],
                name: "addToPossessed",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "address",
                        name: "_from",
                        type: "address",
                    },
                    {
                        internalType: "address",
                        name: "_to",
                        type: "address",
                    },
                    {
                        internalType: "address",
                        name: "_collectionAddress",
                        type: "address",
                    },
                    {
                        internalType: "uint256",
                        name: "_tokenId",
                        type: "uint256",
                    },
                ],
                name: "removeToPossessed",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "address",
                        name: "_collectionAddress",
                        type: "address",
                    },
                ],
                name: "getNftsNumberXCollection",
                outputs: [
                    {
                        internalType: "uint256",
                        name: "",
                        type: "uint256",
                    },
                ],
                stateMutability: "view",
                type: "function",
                constant: true,
            },
            {
                inputs: [
                    {
                        internalType: "address",
                        name: "_collectionAddress",
                        type: "address",
                    },
                ],
                name: "isCollection",
                outputs: [
                    {
                        internalType: "bool",
                        name: "",
                        type: "bool",
                    },
                ],
                stateMutability: "view",
                type: "function",
                constant: true,
            },
            {
                inputs: [
                    {
                        internalType: "address",
                        name: "_collectionAddress",
                        type: "address",
                    },
                ],
                name: "isCreatedByMe",
                outputs: [
                    {
                        internalType: "bool",
                        name: "",
                        type: "bool",
                    },
                ],
                stateMutability: "view",
                type: "function",
                constant: true,
            },
            {
                inputs: [
                    {
                        internalType: "string",
                        name: "_version",
                        type: "string",
                    },
                ],
                name: "upgradeVersion",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "string",
                        name: "_type",
                        type: "string",
                    },
                ],
                name: "addTypeToTypesAllowed",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "string",
                        name: "_typeToCheck",
                        type: "string",
                    },
                ],
                name: "isTypeAllowed",
                outputs: [
                    {
                        internalType: "bool",
                        name: "",
                        type: "bool",
                    },
                ],
                stateMutability: "view",
                type: "function",
                constant: true,
            },
            {
                inputs: [
                    {
                        internalType: "string",
                        name: "_symbol",
                        type: "string",
                    },
                ],
                name: "getCollectionBySymbol",
                outputs: [
                    {
                        internalType: "address",
                        name: "",
                        type: "address",
                    },
                ],
                stateMutability: "view",
                type: "function",
                constant: true,
            },
            {
                inputs: [
                    {
                        internalType: "string",
                        name: "_symbol",
                        type: "string",
                    },
                ],
                name: "isSymbolAvailable",
                outputs: [
                    {
                        internalType: "bool",
                        name: "",
                        type: "bool",
                    },
                ],
                stateMutability: "view",
                type: "function",
                constant: true,
            },
            {
                inputs: [
                    {
                        internalType: "address",
                        name: "_hashRegistryAddress",
                        type: "address",
                    },
                ],
                name: "setHashRegistryAddress",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
        ],
        NFT_FCTRY_ADDR: "0xbb7f1AF793f2f6a835aA44E0E0a4a226Ca1625de",

        MKP_FCTRY_ABI: [
            {
                anonymous: false,
                inputs: [
                    {
                        indexed: false,
                        internalType: "uint8",
                        name: "version",
                        type: "uint8",
                    },
                ],
                name: "Initialized",
                type: "event",
            },
            {
                anonymous: false,
                inputs: [
                    {
                        indexed: true,
                        internalType: "address",
                        name: "owner",
                        type: "address",
                    },
                    {
                        indexed: true,
                        internalType: "address",
                        name: "marketplaceAddr",
                        type: "address",
                    },
                    {
                        indexed: false,
                        internalType: "address",
                        name: "nftAddr",
                        type: "address",
                    },
                    {
                        indexed: false,
                        internalType: "uint256",
                        name: "nftId",
                        type: "uint256",
                    },
                    {
                        indexed: false,
                        internalType: "address",
                        name: "tokenAddr",
                        type: "address",
                    },
                    {
                        indexed: false,
                        internalType: "uint256",
                        name: "amountRequired",
                        type: "uint256",
                    },
                    {
                        indexed: false,
                        internalType: "uint256",
                        name: "timestamp",
                        type: "uint256",
                    },
                    {
                        indexed: false,
                        internalType: "string",
                        name: "contractVersion",
                        type: "string",
                    },
                ],
                name: "MarketplaceAdded",
                type: "event",
            },
            {
                inputs: [],
                name: "name",
                outputs: [
                    {
                        internalType: "string",
                        name: "",
                        type: "string",
                    },
                ],
                stateMutability: "view",
                type: "function",
                constant: true,
            },
            {
                inputs: [],
                name: "owner",
                outputs: [
                    {
                        internalType: "address",
                        name: "",
                        type: "address",
                    },
                ],
                stateMutability: "view",
                type: "function",
                constant: true,
            },
            {
                inputs: [],
                name: "realm",
                outputs: [
                    {
                        internalType: "string",
                        name: "",
                        type: "string",
                    },
                ],
                stateMutability: "view",
                type: "function",
                constant: true,
            },
            {
                inputs: [],
                name: "version",
                outputs: [
                    {
                        internalType: "string",
                        name: "",
                        type: "string",
                    },
                ],
                stateMutability: "view",
                type: "function",
                constant: true,
            },
            {
                inputs: [
                    {
                        internalType: "string",
                        name: "_name",
                        type: "string",
                    },
                    {
                        internalType: "string",
                        name: "_realm",
                        type: "string",
                    },
                    {
                        internalType: "string",
                        name: "_version",
                        type: "string",
                    },
                ],
                name: "initialize",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "address",
                        name: "_nftOffered",
                        type: "address",
                    },
                    {
                        internalType: "uint256",
                        name: "_tokenIdOffered",
                        type: "uint256",
                    },
                    {
                        internalType: "address",
                        name: "_coinRequired",
                        type: "address",
                    },
                    {
                        internalType: "uint256",
                        name: "_amountRequired",
                        type: "uint256",
                    },
                    {
                        internalType: "address",
                        name: "_nftFactoryAddrs",
                        type: "address",
                    },
                ],
                name: "createMarketplace",
                outputs: [
                    {
                        internalType: "address",
                        name: "",
                        type: "address",
                    },
                ],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [],
                name: "getAllMarketplaceAddresses",
                outputs: [
                    {
                        internalType: "address[]",
                        name: "",
                        type: "address[]",
                    },
                ],
                stateMutability: "view",
                type: "function",
                constant: true,
            },
            {
                inputs: [
                    {
                        internalType: "string",
                        name: "_version",
                        type: "string",
                    },
                ],
                name: "upgradeVersion",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "address",
                        name: "_addr",
                        type: "address",
                    },
                ],
                name: "isAddressMarketplace",
                outputs: [
                    {
                        internalType: "bool",
                        name: "",
                        type: "bool",
                    },
                ],
                stateMutability: "view",
                type: "function",
                constant: true,
            },
        ],
        MKP_FCTRY_ADDR: "0x45029cfDecF2f8095076A88c8f0f143d933BE513",

        TKN_TMPLT_ABI: [
            {
                anonymous: false,
                inputs: [
                    {
                        indexed: true,
                        internalType: "address",
                        name: "owner",
                        type: "address",
                    },
                    {
                        indexed: true,
                        internalType: "address",
                        name: "spender",
                        type: "address",
                    },
                    {
                        indexed: false,
                        internalType: "uint256",
                        name: "value",
                        type: "uint256",
                    },
                ],
                name: "Approval",
                type: "event",
            },
            {
                anonymous: false,
                inputs: [
                    {
                        indexed: false,
                        internalType: "uint8",
                        name: "version",
                        type: "uint8",
                    },
                ],
                name: "Initialized",
                type: "event",
            },
            {
                anonymous: false,
                inputs: [
                    {
                        indexed: true,
                        internalType: "bytes32",
                        name: "role",
                        type: "bytes32",
                    },
                    {
                        indexed: true,
                        internalType: "bytes32",
                        name: "previousAdminRole",
                        type: "bytes32",
                    },
                    {
                        indexed: true,
                        internalType: "bytes32",
                        name: "newAdminRole",
                        type: "bytes32",
                    },
                ],
                name: "RoleAdminChanged",
                type: "event",
            },
            {
                anonymous: false,
                inputs: [
                    {
                        indexed: true,
                        internalType: "bytes32",
                        name: "role",
                        type: "bytes32",
                    },
                    {
                        indexed: true,
                        internalType: "address",
                        name: "account",
                        type: "address",
                    },
                    {
                        indexed: true,
                        internalType: "address",
                        name: "sender",
                        type: "address",
                    },
                ],
                name: "RoleGranted",
                type: "event",
            },
            {
                anonymous: false,
                inputs: [
                    {
                        indexed: true,
                        internalType: "bytes32",
                        name: "role",
                        type: "bytes32",
                    },
                    {
                        indexed: true,
                        internalType: "address",
                        name: "account",
                        type: "address",
                    },
                    {
                        indexed: true,
                        internalType: "address",
                        name: "sender",
                        type: "address",
                    },
                ],
                name: "RoleRevoked",
                type: "event",
            },
            {
                anonymous: false,
                inputs: [
                    {
                        indexed: true,
                        internalType: "address",
                        name: "from",
                        type: "address",
                    },
                    {
                        indexed: true,
                        internalType: "address",
                        name: "to",
                        type: "address",
                    },
                    {
                        indexed: false,
                        internalType: "uint256",
                        name: "value",
                        type: "uint256",
                    },
                ],
                name: "Transfer",
                type: "event",
            },
            {
                inputs: [],
                name: "DEFAULT_ADMIN_ROLE",
                outputs: [
                    {
                        internalType: "bytes32",
                        name: "",
                        type: "bytes32",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [],
                name: "MINTER_ROLE",
                outputs: [
                    {
                        internalType: "bytes32",
                        name: "",
                        type: "bytes32",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "address",
                        name: "owner",
                        type: "address",
                    },
                    {
                        internalType: "address",
                        name: "spender",
                        type: "address",
                    },
                ],
                name: "allowance",
                outputs: [
                    {
                        internalType: "uint256",
                        name: "",
                        type: "uint256",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "address",
                        name: "spender",
                        type: "address",
                    },
                    {
                        internalType: "uint256",
                        name: "amount",
                        type: "uint256",
                    },
                ],
                name: "approve",
                outputs: [
                    {
                        internalType: "bool",
                        name: "",
                        type: "bool",
                    },
                ],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "address",
                        name: "account",
                        type: "address",
                    },
                ],
                name: "balanceOf",
                outputs: [
                    {
                        internalType: "uint256",
                        name: "",
                        type: "uint256",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [],
                name: "calendarAddress",
                outputs: [
                    {
                        internalType: "address",
                        name: "",
                        type: "address",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "address",
                        name: "spender",
                        type: "address",
                    },
                    {
                        internalType: "uint256",
                        name: "subtractedValue",
                        type: "uint256",
                    },
                ],
                name: "decreaseAllowance",
                outputs: [
                    {
                        internalType: "bool",
                        name: "",
                        type: "bool",
                    },
                ],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "bytes32",
                        name: "role",
                        type: "bytes32",
                    },
                ],
                name: "getRoleAdmin",
                outputs: [
                    {
                        internalType: "bytes32",
                        name: "",
                        type: "bytes32",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "bytes32",
                        name: "role",
                        type: "bytes32",
                    },
                    {
                        internalType: "address",
                        name: "account",
                        type: "address",
                    },
                ],
                name: "grantRole",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "bytes32",
                        name: "role",
                        type: "bytes32",
                    },
                    {
                        internalType: "address",
                        name: "account",
                        type: "address",
                    },
                ],
                name: "hasRole",
                outputs: [
                    {
                        internalType: "bool",
                        name: "",
                        type: "bool",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "address",
                        name: "spender",
                        type: "address",
                    },
                    {
                        internalType: "uint256",
                        name: "addedValue",
                        type: "uint256",
                    },
                ],
                name: "increaseAllowance",
                outputs: [
                    {
                        internalType: "bool",
                        name: "",
                        type: "bool",
                    },
                ],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [],
                name: "itemMarketplaceAddress",
                outputs: [
                    {
                        internalType: "address",
                        name: "",
                        type: "address",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [],
                name: "name",
                outputs: [
                    {
                        internalType: "string",
                        name: "",
                        type: "string",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [],
                name: "realm",
                outputs: [
                    {
                        internalType: "string",
                        name: "",
                        type: "string",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "bytes32",
                        name: "role",
                        type: "bytes32",
                    },
                    {
                        internalType: "address",
                        name: "account",
                        type: "address",
                    },
                ],
                name: "renounceRole",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "bytes32",
                        name: "role",
                        type: "bytes32",
                    },
                    {
                        internalType: "address",
                        name: "account",
                        type: "address",
                    },
                ],
                name: "revokeRole",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "bytes4",
                        name: "interfaceId",
                        type: "bytes4",
                    },
                ],
                name: "supportsInterface",
                outputs: [
                    {
                        internalType: "bool",
                        name: "",
                        type: "bool",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [],
                name: "symbol",
                outputs: [
                    {
                        internalType: "string",
                        name: "",
                        type: "string",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [],
                name: "totalSupply",
                outputs: [
                    {
                        internalType: "uint256",
                        name: "",
                        type: "uint256",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "string",
                        name: "_name",
                        type: "string",
                    },
                    {
                        internalType: "string",
                        name: "_symbol",
                        type: "string",
                    },
                    {
                        internalType: "uint8",
                        name: "_decimals",
                        type: "uint8",
                    },
                    {
                        internalType: "string",
                        name: "_logoURL",
                        type: "string",
                    },
                    {
                        internalType: "string",
                        name: "_logoHash",
                        type: "string",
                    },
                    {
                        internalType: "uint256",
                        name: "_totalSupply",
                        type: "uint256",
                    },
                    {
                        internalType: "address",
                        name: "_owner",
                        type: "address",
                    },
                    {
                        internalType: "string",
                        name: "_contractHash",
                        type: "string",
                    },
                    {
                        internalType: "address",
                        name: "_tokenFactoryAddress",
                        type: "address",
                    },
                    {
                        internalType: "string",
                        name: "_realm",
                        type: "string",
                    },
                    {
                        internalType: "string",
                        name: "_version",
                        type: "string",
                    },
                ],
                name: "initialize",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "address",
                        name: "_recipient",
                        type: "address",
                    },
                    {
                        internalType: "uint256",
                        name: "_amount",
                        type: "uint256",
                    },
                ],
                name: "transfer",
                outputs: [
                    {
                        internalType: "bool",
                        name: "",
                        type: "bool",
                    },
                ],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "address",
                        name: "_from",
                        type: "address",
                    },
                    {
                        internalType: "address",
                        name: "_to",
                        type: "address",
                    },
                    {
                        internalType: "uint256",
                        name: "_amount",
                        type: "uint256",
                    },
                ],
                name: "transferFrom",
                outputs: [
                    {
                        internalType: "bool",
                        name: "",
                        type: "bool",
                    },
                ],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "address",
                        name: "_recipient",
                        type: "address",
                    },
                    {
                        internalType: "uint256",
                        name: "_amount",
                        type: "uint256",
                    },
                ],
                name: "mint",
                outputs: [
                    {
                        internalType: "bool",
                        name: "",
                        type: "bool",
                    },
                ],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "address",
                        name: "_addr",
                        type: "address",
                    },
                ],
                name: "giveRoleMinterTo",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "address",
                        name: "_recipient",
                        type: "address",
                    },
                    {
                        internalType: "uint256",
                        name: "_amount",
                        type: "uint256",
                    },
                ],
                name: "reward",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "address",
                        name: "_calendarAddress",
                        type: "address",
                    },
                    {
                        internalType: "address",
                        name: "_itemMarketplace",
                        type: "address",
                    },
                ],
                name: "setLibraryOfThingsAddresses",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [],
                name: "getLogoURL",
                outputs: [
                    {
                        internalType: "string",
                        name: "",
                        type: "string",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [],
                name: "getLogoHash",
                outputs: [
                    {
                        internalType: "string",
                        name: "",
                        type: "string",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [],
                name: "getContractHash",
                outputs: [
                    {
                        internalType: "string",
                        name: "",
                        type: "string",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [],
                name: "getOwner",
                outputs: [
                    {
                        internalType: "address",
                        name: "",
                        type: "address",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [],
                name: "decimals",
                outputs: [
                    {
                        internalType: "uint8",
                        name: "",
                        type: "uint8",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [],
                name: "getVersion",
                outputs: [
                    {
                        internalType: "string",
                        name: "",
                        type: "string",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "string",
                        name: "_version",
                        type: "string",
                    },
                ],
                name: "upgradeVersion",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
        ],
        TKN_CRWDSL_ABI: [
            {
                anonymous: false,
                inputs: [
                    {
                        indexed: true,
                        internalType: "address",
                        name: "_owner",
                        type: "address",
                    },
                    {
                        indexed: false,
                        internalType: "string",
                        name: "_title",
                        type: "string",
                    },
                    {
                        indexed: false,
                        internalType: "string",
                        name: "contractVersion",
                        type: "string",
                    },
                ],
                name: "CapReached",
                type: "event",
            },
            {
                anonymous: false,
                inputs: [
                    {
                        indexed: true,
                        internalType: "address",
                        name: "_owner",
                        type: "address",
                    },
                    {
                        indexed: false,
                        internalType: "string",
                        name: "_title",
                        type: "string",
                    },
                    {
                        indexed: false,
                        internalType: "string",
                        name: "contractVersion",
                        type: "string",
                    },
                ],
                name: "CrowdsaleStopped",
                type: "event",
            },
            {
                anonymous: false,
                inputs: [
                    {
                        indexed: true,
                        internalType: "address",
                        name: "_owner",
                        type: "address",
                    },
                    {
                        indexed: false,
                        internalType: "string",
                        name: "_title",
                        type: "string",
                    },
                    {
                        indexed: false,
                        internalType: "string",
                        name: "contractVersion",
                        type: "string",
                    },
                ],
                name: "CrowdsaleUnlock",
                type: "event",
            },
            {
                anonymous: false,
                inputs: [
                    {
                        indexed: false,
                        internalType: "uint8",
                        name: "version",
                        type: "uint8",
                    },
                ],
                name: "Initialized",
                type: "event",
            },
            {
                anonymous: false,
                inputs: [
                    {
                        indexed: true,
                        internalType: "address",
                        name: "_to",
                        type: "address",
                    },
                    {
                        indexed: false,
                        internalType: "uint256",
                        name: "_amount",
                        type: "uint256",
                    },
                    {
                        indexed: false,
                        internalType: "string",
                        name: "_title",
                        type: "string",
                    },
                    {
                        indexed: false,
                        internalType: "uint256",
                        name: "_decimals",
                        type: "uint256",
                    },
                    {
                        indexed: false,
                        internalType: "string",
                        name: "contractVersion",
                        type: "string",
                    },
                ],
                name: "RefundTo",
                type: "event",
            },
            {
                anonymous: false,
                inputs: [
                    {
                        indexed: true,
                        internalType: "address",
                        name: "_from",
                        type: "address",
                    },
                    {
                        indexed: false,
                        internalType: "uint256",
                        name: "_amount",
                        type: "uint256",
                    },
                    {
                        indexed: false,
                        internalType: "string",
                        name: "_title",
                        type: "string",
                    },
                    {
                        indexed: false,
                        internalType: "uint256",
                        name: "_decimals",
                        type: "uint256",
                    },
                    {
                        indexed: false,
                        internalType: "string",
                        name: "contractVersion",
                        type: "string",
                    },
                ],
                name: "UserJoin",
                type: "event",
            },
            {
                inputs: [],
                name: "TOSHash",
                outputs: [
                    {
                        internalType: "string",
                        name: "",
                        type: "string",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [],
                name: "acceptRatio",
                outputs: [
                    {
                        internalType: "uint256",
                        name: "",
                        type: "uint256",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [],
                name: "description",
                outputs: [
                    {
                        internalType: "string",
                        name: "",
                        type: "string",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [],
                name: "end",
                outputs: [
                    {
                        internalType: "uint256",
                        name: "",
                        type: "uint256",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [],
                name: "firstlifePlaceID",
                outputs: [
                    {
                        internalType: "string",
                        name: "",
                        type: "string",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [],
                name: "giveRatio",
                outputs: [
                    {
                        internalType: "uint256",
                        name: "",
                        type: "uint256",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [],
                name: "logoHash",
                outputs: [
                    {
                        internalType: "string",
                        name: "",
                        type: "string",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [],
                name: "maxCap",
                outputs: [
                    {
                        internalType: "uint256",
                        name: "",
                        type: "uint256",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [],
                name: "owner",
                outputs: [
                    {
                        internalType: "address",
                        name: "",
                        type: "address",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [],
                name: "raised",
                outputs: [
                    {
                        internalType: "uint256",
                        name: "",
                        type: "uint256",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [],
                name: "realm",
                outputs: [
                    {
                        internalType: "string",
                        name: "",
                        type: "string",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [],
                name: "start",
                outputs: [
                    {
                        internalType: "uint256",
                        name: "",
                        type: "uint256",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [],
                name: "status",
                outputs: [
                    {
                        internalType: "enum TokenCrowdsaleLib.Status",
                        name: "",
                        type: "uint8",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [],
                name: "title",
                outputs: [
                    {
                        internalType: "string",
                        name: "",
                        type: "string",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [],
                name: "tokenToAcceptAddr",
                outputs: [
                    {
                        internalType: "address",
                        name: "",
                        type: "address",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [],
                name: "tokenToGiveAddr",
                outputs: [
                    {
                        internalType: "address",
                        name: "",
                        type: "address",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [],
                name: "version",
                outputs: [
                    {
                        internalType: "string",
                        name: "",
                        type: "string",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "address",
                        name: "_tokenToGive",
                        type: "address",
                    },
                    {
                        internalType: "address",
                        name: "_tokenToAccept",
                        type: "address",
                    },
                    {
                        internalType: "uint256[]",
                        name: "additionalData",
                        type: "uint256[]",
                    },
                    {
                        internalType: "address",
                        name: "_owner",
                        type: "address",
                    },
                    {
                        internalType: "string[]",
                        name: "metadata",
                        type: "string[]",
                    },
                    {
                        internalType: "address",
                        name: "_crowdsaleFactory",
                        type: "address",
                    },
                    {
                        internalType: "string",
                        name: "_realm",
                        type: "string",
                    },
                    {
                        internalType: "string",
                        name: "_version",
                        type: "string",
                    },
                ],
                name: "initialize",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [],
                name: "getMyReservation",
                outputs: [
                    {
                        internalType: "uint256",
                        name: "",
                        type: "uint256",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [],
                name: "stopCrowdsale",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [],
                name: "unlockCrowdsale",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "uint256",
                        name: "_amount",
                        type: "uint256",
                    },
                ],
                name: "refundMe",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "uint256",
                        name: "_amount",
                        type: "uint256",
                    },
                ],
                name: "joinCrowdsale",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [],
                name: "getContributors",
                outputs: [
                    {
                        internalType: "address[]",
                        name: "",
                        type: "address[]",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [],
                name: "getCrowdsaleInfo",
                outputs: [
                    {
                        internalType: "address",
                        name: "",
                        type: "address",
                    },
                    {
                        internalType: "string",
                        name: "",
                        type: "string",
                    },
                    {
                        internalType: "string",
                        name: "",
                        type: "string",
                    },
                    {
                        internalType: "string",
                        name: "",
                        type: "string",
                    },
                    {
                        internalType: "string",
                        name: "",
                        type: "string",
                    },
                    {
                        internalType: "uint256",
                        name: "",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "",
                        type: "uint256",
                    },
                    {
                        internalType: "address",
                        name: "",
                        type: "address",
                    },
                    {
                        internalType: "address",
                        name: "",
                        type: "address",
                    },
                    {
                        internalType: "enum TokenCrowdsaleLib.Status",
                        name: "",
                        type: "uint8",
                    },
                    {
                        internalType: "uint256",
                        name: "",
                        type: "uint256",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "string",
                        name: "_version",
                        type: "string",
                    },
                ],
                name: "upgradeVersion",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
        ],
        NFT_TMPLT_ABI: [
            {
                anonymous: false,
                inputs: [
                    {
                        indexed: true,
                        internalType: "address",
                        name: "owner",
                        type: "address",
                    },
                    {
                        indexed: true,
                        internalType: "address",
                        name: "approved",
                        type: "address",
                    },
                    {
                        indexed: true,
                        internalType: "uint256",
                        name: "tokenId",
                        type: "uint256",
                    },
                ],
                name: "Approval",
                type: "event",
            },
            {
                anonymous: false,
                inputs: [
                    {
                        indexed: true,
                        internalType: "address",
                        name: "owner",
                        type: "address",
                    },
                    {
                        indexed: true,
                        internalType: "address",
                        name: "operator",
                        type: "address",
                    },
                    {
                        indexed: false,
                        internalType: "bool",
                        name: "approved",
                        type: "bool",
                    },
                ],
                name: "ApprovalForAll",
                type: "event",
            },
            {
                anonymous: false,
                inputs: [
                    {
                        indexed: false,
                        internalType: "uint256",
                        name: "_fromTokenId",
                        type: "uint256",
                    },
                    {
                        indexed: false,
                        internalType: "uint256",
                        name: "_toTokenId",
                        type: "uint256",
                    },
                ],
                name: "BatchMetadataUpdate",
                type: "event",
            },
            {
                anonymous: false,
                inputs: [
                    {
                        indexed: false,
                        internalType: "uint8",
                        name: "version",
                        type: "uint8",
                    },
                ],
                name: "Initialized",
                type: "event",
            },
            {
                anonymous: false,
                inputs: [
                    {
                        indexed: false,
                        internalType: "uint256",
                        name: "_tokenId",
                        type: "uint256",
                    },
                ],
                name: "MetadataUpdate",
                type: "event",
            },
            {
                anonymous: false,
                inputs: [
                    {
                        indexed: true,
                        internalType: "bytes32",
                        name: "role",
                        type: "bytes32",
                    },
                    {
                        indexed: true,
                        internalType: "bytes32",
                        name: "previousAdminRole",
                        type: "bytes32",
                    },
                    {
                        indexed: true,
                        internalType: "bytes32",
                        name: "newAdminRole",
                        type: "bytes32",
                    },
                ],
                name: "RoleAdminChanged",
                type: "event",
            },
            {
                anonymous: false,
                inputs: [
                    {
                        indexed: true,
                        internalType: "bytes32",
                        name: "role",
                        type: "bytes32",
                    },
                    {
                        indexed: true,
                        internalType: "address",
                        name: "account",
                        type: "address",
                    },
                    {
                        indexed: true,
                        internalType: "address",
                        name: "sender",
                        type: "address",
                    },
                ],
                name: "RoleGranted",
                type: "event",
            },
            {
                anonymous: false,
                inputs: [
                    {
                        indexed: true,
                        internalType: "bytes32",
                        name: "role",
                        type: "bytes32",
                    },
                    {
                        indexed: true,
                        internalType: "address",
                        name: "account",
                        type: "address",
                    },
                    {
                        indexed: true,
                        internalType: "address",
                        name: "sender",
                        type: "address",
                    },
                ],
                name: "RoleRevoked",
                type: "event",
            },
            {
                anonymous: false,
                inputs: [
                    {
                        indexed: true,
                        internalType: "address",
                        name: "from",
                        type: "address",
                    },
                    {
                        indexed: true,
                        internalType: "address",
                        name: "to",
                        type: "address",
                    },
                    {
                        indexed: true,
                        internalType: "uint256",
                        name: "tokenId",
                        type: "uint256",
                    },
                ],
                name: "Transfer",
                type: "event",
            },
            {
                inputs: [],
                name: "DEFAULT_ADMIN_ROLE",
                outputs: [
                    {
                        internalType: "bytes32",
                        name: "",
                        type: "bytes32",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "address",
                        name: "to",
                        type: "address",
                    },
                    {
                        internalType: "uint256",
                        name: "tokenId",
                        type: "uint256",
                    },
                ],
                name: "approve",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "address",
                        name: "owner",
                        type: "address",
                    },
                ],
                name: "balanceOf",
                outputs: [
                    {
                        internalType: "uint256",
                        name: "",
                        type: "uint256",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [],
                name: "calendarAddress",
                outputs: [
                    {
                        internalType: "address",
                        name: "",
                        type: "address",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "uint256",
                        name: "tokenId",
                        type: "uint256",
                    },
                ],
                name: "getApproved",
                outputs: [
                    {
                        internalType: "address",
                        name: "",
                        type: "address",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "bytes32",
                        name: "role",
                        type: "bytes32",
                    },
                ],
                name: "getRoleAdmin",
                outputs: [
                    {
                        internalType: "bytes32",
                        name: "",
                        type: "bytes32",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "bytes32",
                        name: "role",
                        type: "bytes32",
                    },
                    {
                        internalType: "address",
                        name: "account",
                        type: "address",
                    },
                ],
                name: "grantRole",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "bytes32",
                        name: "role",
                        type: "bytes32",
                    },
                    {
                        internalType: "address",
                        name: "account",
                        type: "address",
                    },
                ],
                name: "hasRole",
                outputs: [
                    {
                        internalType: "bool",
                        name: "",
                        type: "bool",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "address",
                        name: "owner",
                        type: "address",
                    },
                    {
                        internalType: "address",
                        name: "operator",
                        type: "address",
                    },
                ],
                name: "isApprovedForAll",
                outputs: [
                    {
                        internalType: "bool",
                        name: "",
                        type: "bool",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [],
                name: "name",
                outputs: [
                    {
                        internalType: "string",
                        name: "",
                        type: "string",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "uint256",
                        name: "tokenId",
                        type: "uint256",
                    },
                ],
                name: "ownerOf",
                outputs: [
                    {
                        internalType: "address",
                        name: "",
                        type: "address",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [],
                name: "realm",
                outputs: [
                    {
                        internalType: "string",
                        name: "",
                        type: "string",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "bytes32",
                        name: "role",
                        type: "bytes32",
                    },
                    {
                        internalType: "address",
                        name: "account",
                        type: "address",
                    },
                ],
                name: "renounceRole",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "bytes32",
                        name: "role",
                        type: "bytes32",
                    },
                    {
                        internalType: "address",
                        name: "account",
                        type: "address",
                    },
                ],
                name: "revokeRole",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "address",
                        name: "operator",
                        type: "address",
                    },
                    {
                        internalType: "bool",
                        name: "approved",
                        type: "bool",
                    },
                ],
                name: "setApprovalForAll",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [],
                name: "symbol",
                outputs: [
                    {
                        internalType: "string",
                        name: "",
                        type: "string",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "address",
                        name: "from",
                        type: "address",
                    },
                    {
                        internalType: "address",
                        name: "to",
                        type: "address",
                    },
                    {
                        internalType: "uint256",
                        name: "tokenId",
                        type: "uint256",
                    },
                ],
                name: "transferFrom",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [],
                name: "version",
                outputs: [
                    {
                        internalType: "string",
                        name: "",
                        type: "string",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "string",
                        name: "_name",
                        type: "string",
                    },
                    {
                        internalType: "string",
                        name: "_symbol",
                        type: "string",
                    },
                    {
                        internalType: "string",
                        name: "_collectionURI",
                        type: "string",
                    },
                    {
                        internalType: "address",
                        name: "_nftFactory",
                        type: "address",
                    },
                    {
                        internalType: "string",
                        name: "_version",
                        type: "string",
                    },
                    {
                        internalType: "address",
                        name: "_owner",
                        type: "address",
                    },
                    {
                        internalType: "string",
                        name: "_typeNFT",
                        type: "string",
                    },
                    {
                        internalType: "string",
                        name: "_realm",
                        type: "string",
                    },
                ],
                name: "initialize",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "address",
                        name: "to",
                        type: "address",
                    },
                    {
                        internalType: "string",
                        name: "_uri",
                        type: "string",
                    },
                    {
                        internalType: "bool",
                        name: "_isTransferable",
                        type: "bool",
                    },
                ],
                name: "safeMint",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [],
                name: "getNextTokenIdCounter",
                outputs: [
                    {
                        internalType: "uint256",
                        name: "",
                        type: "uint256",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "uint256",
                        name: "_tokenId",
                        type: "uint256",
                    },
                ],
                name: "isNftTransferable",
                outputs: [
                    {
                        internalType: "bool",
                        name: "",
                        type: "bool",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [],
                name: "contractURI",
                outputs: [
                    {
                        internalType: "string",
                        name: "",
                        type: "string",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [],
                name: "contractOwner",
                outputs: [
                    {
                        internalType: "address",
                        name: "",
                        type: "address",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "address",
                        name: "_from",
                        type: "address",
                    },
                    {
                        internalType: "address",
                        name: "_to",
                        type: "address",
                    },
                    {
                        internalType: "uint256",
                        name: "_tokenId",
                        type: "uint256",
                    },
                ],
                name: "safeTransferFrom",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "address",
                        name: "from",
                        type: "address",
                    },
                    {
                        internalType: "address",
                        name: "to",
                        type: "address",
                    },
                    {
                        internalType: "uint256",
                        name: "tokenId",
                        type: "uint256",
                    },
                    {
                        internalType: "bytes",
                        name: "data",
                        type: "bytes",
                    },
                ],
                name: "safeTransferFrom",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "string",
                        name: "_version",
                        type: "string",
                    },
                ],
                name: "upgradeVersion",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "uint256",
                        name: "_tokenId",
                        type: "uint256",
                    },
                ],
                name: "burn",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "uint256",
                        name: "tokenId",
                        type: "uint256",
                    },
                ],
                name: "tokenURI",
                outputs: [
                    {
                        internalType: "string",
                        name: "",
                        type: "string",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "bytes4",
                        name: "interfaceId",
                        type: "bytes4",
                    },
                ],
                name: "supportsInterface",
                outputs: [
                    {
                        internalType: "bool",
                        name: "",
                        type: "bool",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [],
                name: "getType",
                outputs: [
                    {
                        internalType: "string",
                        name: "",
                        type: "string",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
        ],
        MKP_TMPLT_ABI: [
            {
                anonymous: false,
                inputs: [
                    {
                        indexed: false,
                        internalType: "uint8",
                        name: "version",
                        type: "uint8",
                    },
                ],
                name: "Initialized",
                type: "event",
            },
            {
                anonymous: false,
                inputs: [
                    {
                        indexed: true,
                        internalType: "address",
                        name: "contractAddress",
                        type: "address",
                    },
                    {
                        indexed: true,
                        internalType: "address",
                        name: "owner",
                        type: "address",
                    },
                    {
                        indexed: false,
                        internalType: "string",
                        name: "contractVersion",
                        type: "string",
                    },
                ],
                name: "MarketplaceCancelled",
                type: "event",
            },
            {
                anonymous: false,
                inputs: [
                    {
                        indexed: true,
                        internalType: "address",
                        name: "contractAddress",
                        type: "address",
                    },
                    {
                        indexed: true,
                        internalType: "address",
                        name: "owner",
                        type: "address",
                    },
                    {
                        indexed: true,
                        internalType: "address",
                        name: "buyer",
                        type: "address",
                    },
                    {
                        indexed: false,
                        internalType: "string",
                        name: "contractVersion",
                        type: "string",
                    },
                ],
                name: "MarketplaceCompleted",
                type: "event",
            },
            {
                anonymous: false,
                inputs: [
                    {
                        indexed: true,
                        internalType: "bytes32",
                        name: "role",
                        type: "bytes32",
                    },
                    {
                        indexed: true,
                        internalType: "bytes32",
                        name: "previousAdminRole",
                        type: "bytes32",
                    },
                    {
                        indexed: true,
                        internalType: "bytes32",
                        name: "newAdminRole",
                        type: "bytes32",
                    },
                ],
                name: "RoleAdminChanged",
                type: "event",
            },
            {
                anonymous: false,
                inputs: [
                    {
                        indexed: true,
                        internalType: "bytes32",
                        name: "role",
                        type: "bytes32",
                    },
                    {
                        indexed: true,
                        internalType: "address",
                        name: "account",
                        type: "address",
                    },
                    {
                        indexed: true,
                        internalType: "address",
                        name: "sender",
                        type: "address",
                    },
                ],
                name: "RoleGranted",
                type: "event",
            },
            {
                anonymous: false,
                inputs: [
                    {
                        indexed: true,
                        internalType: "bytes32",
                        name: "role",
                        type: "bytes32",
                    },
                    {
                        indexed: true,
                        internalType: "address",
                        name: "account",
                        type: "address",
                    },
                    {
                        indexed: true,
                        internalType: "address",
                        name: "sender",
                        type: "address",
                    },
                ],
                name: "RoleRevoked",
                type: "event",
            },
            {
                inputs: [],
                name: "DEFAULT_ADMIN_ROLE",
                outputs: [
                    {
                        internalType: "bytes32",
                        name: "",
                        type: "bytes32",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [],
                name: "coinRequiredAddr",
                outputs: [
                    {
                        internalType: "address",
                        name: "",
                        type: "address",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [],
                name: "factoryAddress",
                outputs: [
                    {
                        internalType: "address",
                        name: "",
                        type: "address",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "bytes32",
                        name: "role",
                        type: "bytes32",
                    },
                ],
                name: "getRoleAdmin",
                outputs: [
                    {
                        internalType: "bytes32",
                        name: "",
                        type: "bytes32",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "bytes32",
                        name: "role",
                        type: "bytes32",
                    },
                    {
                        internalType: "address",
                        name: "account",
                        type: "address",
                    },
                ],
                name: "grantRole",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "bytes32",
                        name: "role",
                        type: "bytes32",
                    },
                    {
                        internalType: "address",
                        name: "account",
                        type: "address",
                    },
                ],
                name: "hasRole",
                outputs: [
                    {
                        internalType: "bool",
                        name: "",
                        type: "bool",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [],
                name: "nftFactoryAddress",
                outputs: [
                    {
                        internalType: "address",
                        name: "",
                        type: "address",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [],
                name: "nftOfferedAddr",
                outputs: [
                    {
                        internalType: "address",
                        name: "",
                        type: "address",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [],
                name: "owner",
                outputs: [
                    {
                        internalType: "address",
                        name: "",
                        type: "address",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [],
                name: "realm",
                outputs: [
                    {
                        internalType: "string",
                        name: "",
                        type: "string",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "bytes32",
                        name: "role",
                        type: "bytes32",
                    },
                    {
                        internalType: "address",
                        name: "account",
                        type: "address",
                    },
                ],
                name: "renounceRole",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "bytes32",
                        name: "role",
                        type: "bytes32",
                    },
                    {
                        internalType: "address",
                        name: "account",
                        type: "address",
                    },
                ],
                name: "revokeRole",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [],
                name: "status",
                outputs: [
                    {
                        internalType: "enum MarketplaceTemplateLib.Status",
                        name: "",
                        type: "uint8",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "bytes4",
                        name: "interfaceId",
                        type: "bytes4",
                    },
                ],
                name: "supportsInterface",
                outputs: [
                    {
                        internalType: "bool",
                        name: "",
                        type: "bool",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [],
                name: "tokenIdOffered",
                outputs: [
                    {
                        internalType: "uint256",
                        name: "",
                        type: "uint256",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [],
                name: "version",
                outputs: [
                    {
                        internalType: "string",
                        name: "",
                        type: "string",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "address",
                        name: "_nftOffered",
                        type: "address",
                    },
                    {
                        internalType: "uint256",
                        name: "_tokenIdOffered",
                        type: "uint256",
                    },
                    {
                        internalType: "address",
                        name: "_coinRequired",
                        type: "address",
                    },
                    {
                        internalType: "address",
                        name: "_owner",
                        type: "address",
                    },
                    {
                        internalType: "uint256",
                        name: "_amountRequired",
                        type: "uint256",
                    },
                    {
                        internalType: "address",
                        name: "_factoryAddress",
                        type: "address",
                    },
                    {
                        internalType: "address",
                        name: "_nftFactoryAddress",
                        type: "address",
                    },
                    {
                        internalType: "string",
                        name: "_realm",
                        type: "string",
                    },
                    {
                        internalType: "string",
                        name: "_version",
                        type: "string",
                    },
                ],
                name: "initialize",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [],
                name: "acceptMarketplace",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [],
                name: "cancelMarketplace",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [],
                name: "getMarketplaceData",
                outputs: [
                    {
                        internalType: "address",
                        name: "",
                        type: "address",
                    },
                    {
                        internalType: "enum MarketplaceTemplateLib.Status",
                        name: "",
                        type: "uint8",
                    },
                    {
                        internalType: "address",
                        name: "",
                        type: "address",
                    },
                    {
                        internalType: "uint256",
                        name: "",
                        type: "uint256",
                    },
                    {
                        internalType: "address",
                        name: "",
                        type: "address",
                    },
                    {
                        internalType: "uint256",
                        name: "",
                        type: "uint256",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [],
                name: "getNftOfferedAddr",
                outputs: [
                    {
                        internalType: "address",
                        name: "",
                        type: "address",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [],
                name: "getCoinRequiredAddr",
                outputs: [
                    {
                        internalType: "address",
                        name: "",
                        type: "address",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "string",
                        name: "_version",
                        type: "string",
                    },
                ],
                name: "upgradeVersion",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "address",
                        name: "_operator",
                        type: "address",
                    },
                    {
                        internalType: "address",
                        name: "_from",
                        type: "address",
                    },
                    {
                        internalType: "uint256",
                        name: "_tokenId",
                        type: "uint256",
                    },
                    {
                        internalType: "bytes",
                        name: "_data",
                        type: "bytes",
                    },
                ],
                name: "onERC721Received",
                outputs: [
                    {
                        internalType: "bytes4",
                        name: "",
                        type: "bytes4",
                    },
                ],
                stateMutability: "nonpayable",
                type: "function",
            },
        ],
        CCDAO_ABI: [
            {
                inputs: [
                    {
                        internalType: "address",
                        name: "_tokenFactory",
                        type: "address",
                    },
                    {
                        internalType: "address",
                        name: "_crowdsaleFactory",
                        type: "address",
                    },
                    {
                        internalType: "address",
                        name: "_exchangeFactory",
                        type: "address",
                    },
                    {
                        internalType: "string",
                        name: "_name",
                        type: "string",
                    },
                    {
                        internalType: "string",
                        name: "_firstlifePlaceID",
                        type: "string",
                    },
                    {
                        internalType: "string",
                        name: "_description_cid",
                        type: "string",
                    },
                    {
                        internalType: "address",
                        name: "_creator",
                        type: "address",
                    },
                    {
                        internalType: "address",
                        name: "_daoFactory",
                        type: "address",
                    },
                    {
                        internalType: "string",
                        name: "_realm",
                        type: "string",
                    },
                ],
                stateMutability: "nonpayable",
                type: "constructor",
            },
            {
                anonymous: false,
                inputs: [
                    {
                        indexed: true,
                        internalType: "address",
                        name: "from",
                        type: "address",
                    },
                    {
                        indexed: false,
                        internalType: "address",
                        name: "member",
                        type: "address",
                    },
                    {
                        indexed: false,
                        internalType: "uint256",
                        name: "newRole",
                        type: "uint256",
                    },
                ],
                name: "UserDemoted",
                type: "event",
            },
            {
                anonymous: false,
                inputs: [
                    {
                        indexed: true,
                        internalType: "address",
                        name: "member",
                        type: "address",
                    },
                ],
                name: "UserJoined",
                type: "event",
            },
            {
                anonymous: false,
                inputs: [
                    {
                        indexed: true,
                        internalType: "address",
                        name: "from",
                        type: "address",
                    },
                    {
                        indexed: false,
                        internalType: "address",
                        name: "member",
                        type: "address",
                    },
                ],
                name: "UserKicked",
                type: "event",
            },
            {
                anonymous: false,
                inputs: [
                    {
                        indexed: true,
                        internalType: "address",
                        name: "from",
                        type: "address",
                    },
                    {
                        indexed: false,
                        internalType: "address",
                        name: "member",
                        type: "address",
                    },
                    {
                        indexed: false,
                        internalType: "uint256",
                        name: "newRole",
                        type: "uint256",
                    },
                ],
                name: "UserPromoted",
                type: "event",
            },
            {
                inputs: [],
                name: "creator",
                outputs: [
                    {
                        internalType: "address",
                        name: "",
                        type: "address",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [],
                name: "crowdsaleFactory",
                outputs: [
                    {
                        internalType: "contract CrowdsaleFactory",
                        name: "",
                        type: "address",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [],
                name: "daoFactory",
                outputs: [
                    {
                        internalType: "contract DAOFactory",
                        name: "",
                        type: "address",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [],
                name: "description_cid",
                outputs: [
                    {
                        internalType: "string",
                        name: "",
                        type: "string",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [],
                name: "exchangeFactory",
                outputs: [
                    {
                        internalType: "contract ExchangeFactory",
                        name: "",
                        type: "address",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [],
                name: "firstlifePlaceID",
                outputs: [
                    {
                        internalType: "string",
                        name: "",
                        type: "string",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [],
                name: "name",
                outputs: [
                    {
                        internalType: "string",
                        name: "",
                        type: "string",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [],
                name: "realm",
                outputs: [
                    {
                        internalType: "string",
                        name: "",
                        type: "string",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [],
                name: "tokenFactory",
                outputs: [
                    {
                        internalType: "contract TokenFactory",
                        name: "",
                        type: "address",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [],
                name: "join",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "address",
                        name: "_member",
                        type: "address",
                    },
                ],
                name: "kickMember",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "address",
                        name: "_member",
                        type: "address",
                    },
                    {
                        internalType: "uint256",
                        name: "role",
                        type: "uint256",
                    },
                ],
                name: "demote",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "address",
                        name: "_member",
                        type: "address",
                    },
                    {
                        internalType: "uint256",
                        name: "role",
                        type: "uint256",
                    },
                ],
                name: "promote",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [],
                name: "myRole",
                outputs: [
                    {
                        internalType: "uint256",
                        name: "",
                        type: "uint256",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "string",
                        name: "_symbol",
                        type: "string",
                    },
                    {
                        internalType: "uint256",
                        name: "_amount",
                        type: "uint256",
                    },
                    {
                        internalType: "address",
                        name: "_to",
                        type: "address",
                    },
                ],
                name: "transferToken",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "string",
                        name: "_name",
                        type: "string",
                    },
                    {
                        internalType: "string",
                        name: "_symbol",
                        type: "string",
                    },
                    {
                        internalType: "uint8",
                        name: "_decimals",
                        type: "uint8",
                    },
                    {
                        internalType: "string",
                        name: "_logoURL",
                        type: "string",
                    },
                    {
                        internalType: "string",
                        name: "_logoHash",
                        type: "string",
                    },
                    {
                        internalType: "uint256",
                        name: "_hardCap",
                        type: "uint256",
                    },
                    {
                        internalType: "string",
                        name: "_contractHash",
                        type: "string",
                    },
                ],
                name: "createToken",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "string",
                        name: "_name",
                        type: "string",
                    },
                    {
                        internalType: "uint256",
                        name: "_value",
                        type: "uint256",
                    },
                ],
                name: "mintToken",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "string",
                        name: "symbol",
                        type: "string",
                    },
                    {
                        internalType: "address",
                        name: "_address",
                        type: "address",
                    },
                ],
                name: "makeAuthToken",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "string",
                        name: "symbol",
                        type: "string",
                    },
                    {
                        internalType: "address",
                        name: "_address",
                        type: "address",
                    },
                ],
                name: "authToken",
                outputs: [
                    {
                        internalType: "bool",
                        name: "",
                        type: "bool",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "address",
                        name: "_tokenToGive",
                        type: "address",
                    },
                    {
                        internalType: "address",
                        name: "_tokenToAccept",
                        type: "address",
                    },
                    {
                        internalType: "uint256",
                        name: "_start",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "_end",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "_acceptRatio",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "_giveRatio",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "_maxCap",
                        type: "uint256",
                    },
                    {
                        internalType: "string",
                        name: "_title",
                        type: "string",
                    },
                    {
                        internalType: "string",
                        name: "_description",
                        type: "string",
                    },
                    {
                        internalType: "string",
                        name: "_logoHash",
                        type: "string",
                    },
                    {
                        internalType: "string",
                        name: "_TOSHash",
                        type: "string",
                    },
                    {
                        internalType: "string",
                        name: "_firstlifePlaceID",
                        type: "string",
                    },
                ],
                name: "createCrowdsale",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "address",
                        name: "_crowdsaleID",
                        type: "address",
                    },
                    {
                        internalType: "address",
                        name: "_tokenToGive",
                        type: "address",
                    },
                    {
                        internalType: "uint256",
                        name: "_amount",
                        type: "uint256",
                    },
                ],
                name: "unlockCrowdsale",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "address",
                        name: "_crowdsaleID",
                        type: "address",
                    },
                ],
                name: "stopCrowdsale",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "address",
                        name: "_crowdsaleID",
                        type: "address",
                    },
                    {
                        internalType: "uint256",
                        name: "_amount",
                        type: "uint256",
                    },
                    {
                        internalType: "string",
                        name: "_symbol",
                        type: "string",
                    },
                ],
                name: "joinCrowdsale",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "address",
                        name: "_crowdsaleID",
                        type: "address",
                    },
                    {
                        internalType: "uint256",
                        name: "_amount",
                        type: "uint256",
                    },
                ],
                name: "refundMeCrowdsale",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "address",
                        name: "_crowdsaleID",
                        type: "address",
                    },
                    {
                        internalType: "address",
                        name: "_address",
                        type: "address",
                    },
                ],
                name: "makeAdminCrowdsale",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "address",
                        name: "_crowdsaleID",
                        type: "address",
                    },
                    {
                        internalType: "address",
                        name: "_address",
                        type: "address",
                    },
                ],
                name: "removeAdminCrowdsale",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "address",
                        name: "_crowdsale",
                        type: "address",
                    },
                    {
                        internalType: "address",
                        name: "_address",
                        type: "address",
                    },
                ],
                name: "authCrowdsale",
                outputs: [
                    {
                        internalType: "bool",
                        name: "",
                        type: "bool",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "address[]",
                        name: "_coinsOffered",
                        type: "address[]",
                    },
                    {
                        internalType: "address[]",
                        name: "_coinsRequired",
                        type: "address[]",
                    },
                    {
                        internalType: "uint256[]",
                        name: "_amountsOffered",
                        type: "uint256[]",
                    },
                    {
                        internalType: "uint256[]",
                        name: "_amountsRequired",
                        type: "uint256[]",
                    },
                    {
                        internalType: "uint256",
                        name: "_repeats",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "_expiration",
                        type: "uint256",
                    },
                ],
                name: "createExchange",
                outputs: [
                    {
                        internalType: "address",
                        name: "",
                        type: "address",
                    },
                ],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "address",
                        name: "_exchangeID",
                        type: "address",
                    },
                ],
                name: "cancelExchange",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "address",
                        name: "_exchangeID",
                        type: "address",
                    },
                    {
                        internalType: "uint256",
                        name: "_expiration",
                        type: "uint256",
                    },
                ],
                name: "renewExchange",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "address",
                        name: "_exchangeID",
                        type: "address",
                    },
                    {
                        internalType: "address[]",
                        name: "_coinsRequired",
                        type: "address[]",
                    },
                    {
                        internalType: "uint256[]",
                        name: "_amountsRequired",
                        type: "uint256[]",
                    },
                    {
                        internalType: "uint256",
                        name: "repeats",
                        type: "uint256",
                    },
                ],
                name: "acceptExchange",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "address",
                        name: "_exchangeID",
                        type: "address",
                    },
                    {
                        internalType: "address[]",
                        name: "_coinsOffered",
                        type: "address[]",
                    },
                    {
                        internalType: "uint256[]",
                        name: "_amountsOffered",
                        type: "uint256[]",
                    },
                    {
                        internalType: "uint256",
                        name: "_repeats",
                        type: "uint256",
                    },
                ],
                name: "refillExchange",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "address",
                        name: "_exchangeID",
                        type: "address",
                    },
                    {
                        internalType: "address",
                        name: "_address",
                        type: "address",
                    },
                ],
                name: "makeAdminExchange",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "address",
                        name: "_exchangeID",
                        type: "address",
                    },
                    {
                        internalType: "address",
                        name: "_address",
                        type: "address",
                    },
                ],
                name: "removeAdminExchange",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "address",
                        name: "_exchangeID",
                        type: "address",
                    },
                    {
                        internalType: "address",
                        name: "_address",
                        type: "address",
                    },
                ],
                name: "authExchange",
                outputs: [
                    {
                        internalType: "bool",
                        name: "",
                        type: "bool",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [],
                name: "getDaoOwner",
                outputs: [
                    {
                        internalType: "address",
                        name: "",
                        type: "address",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [],
                name: "getDaoFactory",
                outputs: [
                    {
                        internalType: "address",
                        name: "",
                        type: "address",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [],
                name: "getDaoName",
                outputs: [
                    {
                        internalType: "string",
                        name: "",
                        type: "string",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "address",
                        name: "_member",
                        type: "address",
                    },
                ],
                name: "getRole",
                outputs: [
                    {
                        internalType: "uint256",
                        name: "",
                        type: "uint256",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [],
                name: "getAllMemberAddresses",
                outputs: [
                    {
                        internalType: "address[]",
                        name: "",
                        type: "address[]",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [],
                name: "getFirstlifePlaceId",
                outputs: [
                    {
                        internalType: "string",
                        name: "",
                        type: "string",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [],
                name: "getDaoUri",
                outputs: [
                    {
                        internalType: "string",
                        name: "",
                        type: "string",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
        ],
        TKN_EXCHNG_ABI: [
            {
                anonymous: false,
                inputs: [
                    {
                        indexed: false,
                        internalType: "string",
                        name: "contractVersion",
                        type: "string",
                    },
                ],
                name: "ExchangeCancelled",
                type: "event",
            },
            {
                anonymous: false,
                inputs: [
                    {
                        indexed: true,
                        internalType: "address",
                        name: "owner",
                        type: "address",
                    },
                    {
                        indexed: true,
                        internalType: "address",
                        name: "buyer",
                        type: "address",
                    },
                    {
                        indexed: false,
                        internalType: "uint256",
                        name: "repeats",
                        type: "uint256",
                    },
                    {
                        indexed: false,
                        internalType: "string",
                        name: "contractVersion",
                        type: "string",
                    },
                ],
                name: "ExchangeCompleted",
                type: "event",
            },
            {
                anonymous: false,
                inputs: [
                    {
                        indexed: false,
                        internalType: "uint256",
                        name: "newRepeats",
                        type: "uint256",
                    },
                    {
                        indexed: false,
                        internalType: "string",
                        name: "contractVersion",
                        type: "string",
                    },
                ],
                name: "ExchangeRefilled",
                type: "event",
            },
            {
                anonymous: false,
                inputs: [
                    {
                        indexed: false,
                        internalType: "uint256",
                        name: "newExpiration",
                        type: "uint256",
                    },
                    {
                        indexed: false,
                        internalType: "string",
                        name: "contractVersion",
                        type: "string",
                    },
                ],
                name: "ExchangeRenewed",
                type: "event",
            },
            {
                anonymous: false,
                inputs: [
                    {
                        indexed: false,
                        internalType: "string",
                        name: "contractVersion",
                        type: "string",
                    },
                ],
                name: "ExchangeTerminated",
                type: "event",
            },
            {
                anonymous: false,
                inputs: [
                    {
                        indexed: false,
                        internalType: "uint8",
                        name: "version",
                        type: "uint8",
                    },
                ],
                name: "Initialized",
                type: "event",
            },
            {
                inputs: [
                    {
                        internalType: "uint256",
                        name: "",
                        type: "uint256",
                    },
                ],
                name: "coinsOfferedAddr",
                outputs: [
                    {
                        internalType: "address",
                        name: "",
                        type: "address",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "uint256",
                        name: "",
                        type: "uint256",
                    },
                ],
                name: "coinsRequiredAddr",
                outputs: [
                    {
                        internalType: "address",
                        name: "",
                        type: "address",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [],
                name: "expiration",
                outputs: [
                    {
                        internalType: "uint256",
                        name: "",
                        type: "uint256",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [],
                name: "factoryAddress",
                outputs: [
                    {
                        internalType: "address",
                        name: "",
                        type: "address",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [],
                name: "owner",
                outputs: [
                    {
                        internalType: "address",
                        name: "",
                        type: "address",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [],
                name: "realm",
                outputs: [
                    {
                        internalType: "string",
                        name: "",
                        type: "string",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [],
                name: "status",
                outputs: [
                    {
                        internalType: "enum TokenExchangeLib.Status",
                        name: "",
                        type: "uint8",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [],
                name: "version",
                outputs: [
                    {
                        internalType: "string",
                        name: "",
                        type: "string",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "address[]",
                        name: "_coinsOffered",
                        type: "address[]",
                    },
                    {
                        internalType: "address[]",
                        name: "_coinsRequired",
                        type: "address[]",
                    },
                    {
                        internalType: "address",
                        name: "_owner",
                        type: "address",
                    },
                    {
                        internalType: "uint256[]",
                        name: "_amountsOffered",
                        type: "uint256[]",
                    },
                    {
                        internalType: "uint256[]",
                        name: "_amountsRequired",
                        type: "uint256[]",
                    },
                    {
                        internalType: "uint256",
                        name: "_repeats",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "_expiration",
                        type: "uint256",
                    },
                    {
                        internalType: "address",
                        name: "_factoryAddress",
                        type: "address",
                    },
                    {
                        internalType: "string",
                        name: "_realm",
                        type: "string",
                    },
                    {
                        internalType: "string",
                        name: "_version",
                        type: "string",
                    },
                ],
                name: "initialize",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "uint256",
                        name: "selectedRepeats",
                        type: "uint256",
                    },
                ],
                name: "accceptExchange",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [],
                name: "cancelExchange",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "uint256",
                        name: "newRepeats",
                        type: "uint256",
                    },
                ],
                name: "refillExchange",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "uint256",
                        name: "_expiration",
                        type: "uint256",
                    },
                ],
                name: "renewExchange",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
            {
                inputs: [],
                name: "getExchangeData",
                outputs: [
                    {
                        internalType: "address",
                        name: "",
                        type: "address",
                    },
                    {
                        internalType: "enum TokenExchangeLib.Status",
                        name: "",
                        type: "uint8",
                    },
                    {
                        internalType: "address[]",
                        name: "",
                        type: "address[]",
                    },
                    {
                        internalType: "uint256[]",
                        name: "",
                        type: "uint256[]",
                    },
                    {
                        internalType: "address[]",
                        name: "",
                        type: "address[]",
                    },
                    {
                        internalType: "uint256[]",
                        name: "",
                        type: "uint256[]",
                    },
                    {
                        internalType: "uint256",
                        name: "",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "",
                        type: "uint256",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [],
                name: "getCoinsOfferedAddr",
                outputs: [
                    {
                        internalType: "address[]",
                        name: "",
                        type: "address[]",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [],
                name: "getCoinsRequiredAddr",
                outputs: [
                    {
                        internalType: "address[]",
                        name: "",
                        type: "address[]",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [],
                name: "getExchangeOwner",
                outputs: [
                    {
                        internalType: "address",
                        name: "",
                        type: "address",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [],
                name: "getExchangeRepeats",
                outputs: [
                    {
                        internalType: "uint256",
                        name: "",
                        type: "uint256",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
            {
                inputs: [
                    {
                        internalType: "string",
                        name: "_version",
                        type: "string",
                    },
                ],
                name: "upgradeVersion",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
            },
        ],
    },

    realm: {
        REALM: "chgarden",
    },

    defaultGeoCoordinates: {
        longitude: 5632205.101421998,
        latitude: 854986.3798838389,
    },
};

export default config;
