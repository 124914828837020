import { Grid, makeStyles, useMediaQuery } from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import { useAppSelector } from "../../../store/hooks";
import { ListOfItems } from "./MyItems/ListOfItems";
import { MyInventoryTabs } from "./MyInventoryTabs";
import { UploadItemTitle } from "../UploadItem/UploadItemTitle";
import { useHistory } from "react-router-dom";
import { ListOfActivePreorders } from "./Lendings/Active/ListOfActivePreorders";
import { ListOfPreordersPending } from "./Lendings/Pending/ListOfPreordersPending";
import { ListOfPreordersCompleted } from "./Lendings/Completed/ListOfPreordersCompleted";
import { useTranslation } from "react-i18next";
import { ListOfPreordersCancelled } from "../MyRequests/Cancelled/ListOfCancelledPreorders";
import { CalendarContractContext } from "../../../providers/CalendarContractProvider";
import { TutorialContext } from "../../../providers/TutorialProvider";
import { TutorialFab } from "../Tutorial/TutorialFab";
import theme from "../../../theme/theme";
import { AccountActionWithDashedBorder } from "../MyAccount/AccountActions";

const mainContainerStyle = makeStyles({
    root: {
        position: 'relative',
        '@media (min-width: 837px)': {
            left: '0.2em',
        }
    }
})

export const MyInventory = () => {
    const { openTutorialDesktop, openTutorialMobile } = useContext(TutorialContext);
    const useMainContainerStyle = mainContainerStyle();
    const [activeMenu, setActiveMenu] = useState(0);
    const collections = useAppSelector((state) => state.nft.collectionList);
    const [refreshingItems, setRefreshingItems] = useState(false);
    const [refreshingPending, setRefreshingPending] = useState(false);
    const [refreshActive, setRefreshActive] = useState(false);
    const [refreshCompleted, setRefreshCompleted] = useState(false);
    const { getUserStats } = useContext(CalendarContractContext);
    const [refreshingDenied, setRefreshingDenied] = useState(false);
    const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

    const history = useHistory();
    const { t } = useTranslation('LibOfThings');

    const refresh = () => {
        switch (activeMenu) {
            case 0:
                setRefreshingItems(true);
                break;
            case 1:
                setRefreshingPending(true);
                break;
            case 2:
                setRefreshActive(true);
                break;
            case 3:
                setRefreshCompleted(true);
                break;
            case 4:
                setRefreshingDenied(true);
                break;
        }
    }

    const handleMenuChange = (menuToActivate: number) => {
        setActiveMenu(menuToActivate);
    }

    useEffect(() => {
        (async () => {
            await getUserStats();
        })()
        if (refreshingItems || refreshingPending || refreshActive || refreshCompleted || refreshingDenied) {
            setRefreshingItems(false);
            setRefreshingPending(false);
            setRefreshActive(false);
            setRefreshCompleted(false);
            setRefreshingDenied(false);
        }
    }, [refreshingItems, refreshingPending, refreshActive, refreshCompleted, refreshingDenied])

    const tutorialFabCallback = () => {
        if (isMobile)
            openTutorialMobile();
        else
            openTutorialDesktop();
    }

    return (
        <>
            <Grid container className={useMainContainerStyle.root} spacing={1}>
                {!isMobile &&
                    <Grid item sm={3} onClick={() => { history.push('/items/upload') }} style={{ marginBottom: '30px' }} >
                        <AccountActionWithDashedBorder
                            link={'/items/'}
                            title={'Aggiungi un nuovo oggetto'}
                        />
                    </Grid>
                }
                <Grid item xs={12}>
                    <Grid item xs={12} style={{
                        cursor: 'pointer'
                    }}>
                        <Grid container>
                            <Grid item xs={10} sm={3} md={2} lg={2} onClick={() => history.push('/')} >
                                <UploadItemTitle text={t('seeOwnedItems')} />
                            </Grid>
                            <Grid item xs={2} sm={9} md={9} lg={9}>
                                <img style={{
                                    cursor: 'pointer'
                                }} onClick={() => refresh()} src="/svgs/reload.svg" alt="reload" width={24} height={28} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <MyInventoryTabs
                        handleMenuChange={handleMenuChange}
                    />
                </Grid>
                {
                    (activeMenu === 0) && (
                        <ListOfItems
                            shouldRefresh={refreshingItems}
                            collections={collections}
                        />
                    )
                }
                {
                    (activeMenu === 1) && (
                        <ListOfPreordersPending shouldRefresh={refreshingPending} />
                    )
                }

                {
                    (activeMenu === 2) && (
                        <ListOfActivePreorders shouldRefresh={refreshActive} />
                    )
                }
                {
                    (activeMenu === 3) && (
                        <ListOfPreordersCompleted shouldRefresh={refreshCompleted} />
                    )
                }
                {
                    (activeMenu === 4) && (
                        <ListOfPreordersCancelled shouldRefresh={refreshingDenied} isLender={true} />
                    )
                }
            </Grid>
        </>
    )
}